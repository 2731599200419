import React from 'react';

import {withCommon, LLMenuItem} from '../../../components';
import {DrawerCloseButton} from "../../../pages/user-profile/components";
import {LanguagePicker} from "../../header/header-desktop/components";


const NavigationMenuDrawer = withCommon({
    store: ({store}) => ({
        open: store.drawer.open,
        modifiedConfig: store.modifiedConfig,
    }),
    defaultName: 'NavigationMenuDrawer',
    isTrans: true,
})(({open, trans, modifiedConfig}) => (<>
        <div className="ll-drawer__header--mobile">
            <LanguagePicker/>
            <div>{trans('Main menu')}</div>
            <DrawerCloseButton/>
        </div>
        <div className="drawer-menu">
            <div className="drawer-menu__items">
                {modifiedConfig.navigationDrawerMenu.map((item) => {
                    if (!item.disabled)
                        return (
                            <LLMenuItem
                                item={item}
                                key={item.label}
                                customClasses="drawer-menu__item"
                                onClick={() => {
                                    if (item.drawer) {
                                        open({
                                            side: item.drawer.side,
                                            currentKey: item.drawer.key,
                                        });
                                    }
                                }}
                                icon={<i className={`${item.icon} drawer-menu__item-icon`}/>}
                                label={trans(item.label)}
                            />
                        );
                })}
            </div>
        </div>
    </>
));

export default NavigationMenuDrawer;
