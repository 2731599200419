import {types, getRoot, flow} from 'mobx-state-tree';
import {values} from 'mobx';
import {sortBy} from 'lodash';
import {orderBy} from 'lodash';

import Sports from './instances/sports';
import WithFlags from '../with-flags';
import Search from './search/search';
import {branchConfig, refreshTime, unsuccessCountLimit} from '../config';
import {branchFetches, sportActions, popularTournamentsActions} from './instances/branch-instances-actions';
import BranchItem from './instances/branch-item';
import Tournament from "./instances/tournaments-item";
import {EventsViews} from "../helpers";


const Branch = types
    .model('Branch', {
        id: types.string,
        sports: types.map(types.compose(BranchItem, WithFlags, Sports)),
        /* Array of popular tournaments */
        search: types.optional(types.compose(Search, EventsViews), {}),
        sportsTopIds: types.array(types.string),
        sportsFavoriteIds: types.array(types.string),
        categoriesTopIds: types.array(types.string),
        categoriesFavoriteIds: types.array(types.string),
        tournamentsTopIds: types.array(types.string),
        tournamentsFavoriteIds: types.array(types.string),
        topAndFavoritesLastFetchTime: types.maybeNull(types.Date),
        popularTournaments: types.map(types.compose(BranchItem, Tournament, WithFlags)),
        popularTournamentsLastFetchTime: types.maybeNull(types.Date),
    })
    .extend((s) => ({actions: {...branchFetches(s).sports, ...branchFetches(s).tournaments, ...sportActions(s), ...popularTournamentsActions(s)}}))
    .actions((s) => ({
        setTopIds({sportsTopIds, categoriesTopIds, tournamentsTopIds}) {
            s.sportsTopIds = sportsTopIds;
            s.categoriesTopIds = categoriesTopIds;
            s.tournamentsTopIds = tournamentsTopIds;
        },
        setFavoriteIds({
                           sportsFavoriteIds,
                           categoriesFavoriteIds,
                           tournamentsFavoriteIds,
                       }) {
            s.sportsFavoriteIds = sportsFavoriteIds;
            s.categoriesFavoriteIds = categoriesFavoriteIds;
            s.tournamentsFavoriteIds = tournamentsFavoriteIds;
        },
        setTopAndFavoritesLastFetchTime() {
            s.topAndFavoritesLastFetchTime = Date.now();
        },

        deleteItem(id) {
            s.sports.delete(id);
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Scenarios instructions                                ##
        // ##                                                                                      ##
        // ##========================================================================================

        unsuccessSportsInitializeInstruction({res} = {}) {
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            getRoot(s).betting.setError({type: 'global', error});
        },

        unsuccessPopularTournamentsInitializeInstruction() {
            //хз, нужно ли что-то делать, или просто не показывать популярные турниры
        },

        unsuccessSportsUpdateInstruction({res} = {}) {
            s.setUnsuccessCount(s.unsuccessCount + 1);

            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            if (s.unsuccessCount >= unsuccessCountLimit) {
                getRoot(s).betting.setError({type: 'global', error});
            } else {
                s.setWaitingUpdate(false);
                s.setUpdateForSports();
            }
        },

        unsuccessPopularTournamentsUpdateInstruction({res} = {}) {
            //todo: something
        },

        successSportsInitializeInstruction(
            {
                incomingSportsData,
                requestedSportId,
            } = {}
        ) {
            s.setUnsuccessCount(0);

            if (incomingSportsData.length === 0) {
                getRoot(s).betting.setError({
                    type: 'global',
                    error: 'EMPTY_BETS_ERROR',
                });
            }

            s.putUpdateData({dataSource: incomingSportsData, requestedSportId});
            s.deleteOldSports({sports: incomingSportsData});

            s.setWaitingUpdate(false);
            s.setUpdateForSports();
        },

        successPopularTournamentsInitializeInstruction({incomingData} = {}) {
            s.putUpdatePopularTournamentsData({tournaments: incomingData});
            s.deleteOldPopularTournaments({tournaments: incomingData});

            s.popularTournamentsLastFetchTime = new Date();
            s.setUpdateForPopularTournaments();
        },

        successSportUpdateInstruction({incomingSportsData} = {}) {
            s.setUnsuccessCount(0);

            if (incomingSportsData.length === 0) {
                getRoot(s).betting.setError({
                    type: 'global',
                    error: 'EMPTY_BETS_ERROR',
                });
            }

            s.putUpdateData({dataSource: incomingSportsData});
            s.deleteOldSports({sports: incomingSportsData});

            s.setWaitingUpdate(false);
            s.setUpdateForSports();
        },

        successPopularTournamentsUpdateInstruction({incomingData} = {}) {
            s.putUpdatePopularTournamentsData({tournaments: incomingData});
            s.deleteOldPopularTournaments({tournaments: incomingData});

            s.popularTournamentsLastFetchTime = new Date();
            s.setUpdateForPopularTournaments();
        },

        // ##========================================================================================

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Initialize sport getter                               ##
        // ##                                                                                      ##
        // ##========================================================================================
        initializeSportInstruction: flow(function* fetch({requestedSportId} = {}) {
            initializeSportInstruction: {
                if (
                    s.lastFetchTime &&
                    Date.now() < +new Date(+s.lastFetchTime + refreshTime.sports)
                ) {
                    const timeLeft =
                        +new Date(+s.lastFetchTime + refreshTime.sports) - Date.now();
                    s.setWaitingUpdate(false);
                    s.setUpdateForSports({timeLeft});
                    break initializeSportInstruction;
                }

                const res = yield s.fetchAndConvertDataForSports() || {};

                if (!res || !res.success) {
                    s.unsuccessSportsInitializeInstruction({res});
                    if (getRoot(s).betting.branch.initialFetching) {
                        throw new Error(res.error);
                    }
                } else {
                    s.successSportsInitializeInstruction({
                        incomingSportsData: res.data,
                        requestedSportId,
                    });
                }
                s.setLastFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Initialize popular tournaments getter                               ##
        // ##                                                                                      ##
        // ##========================================================================================
        initializePopularTournamentsInstruction: flow(function* fetch() {
            initializePopularTournamentsInstruction: {
                if (
                    s.popularTournamentsLastFetchTime &&
                    Date.now() < +new Date(+s.popularTournamentsLastFetchTime + refreshTime.sports)
                ) {
                    const timeLeft =
                        +new Date(+s.popularTournamentsLastFetchTime + refreshTime.sports) - Date.now();
                    // s.setWaitingUpdate(false);
                    // s.setUpdateForSports({timeLeft});
                    break initializePopularTournamentsInstruction;
                }

                const res = yield s.fetchAndConvertDataForPopularTournaments() || {};

                if (!res || !res.success) {
                    s.unsuccessPopularTournamentsInitializeInstruction();
                } else {
                    s.successPopularTournamentsInitializeInstruction({
                        incomingData: res.data,
                    });
                }
                s.popularTournamentsLastFetchTime = new Date();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                     Update sports                                    ##
        // ##                                                                                      ##
        // ##========================================================================================

        updateSportInstruction: flow(function* fetch() {
            updateSportInstruction: {
                if (s.check.isUpdateCleanable) {
                    s.setWaitingUpdate(false);
                    clearTimeout(window.__sportsUpdater);
                    break updateSportInstruction;
                }

                if (!s.check.canGetData) {
                    s.setWaitingUpdate(false);
                    s.setUpdateForSports();
                    break updateSportInstruction;
                }

                const res = yield s.fetchAndConvertDataForSports() || {};

                if (!res || !res.success) {
                    s.unsuccessSportsUpdateInstruction({res});
                } else {
                    s.successSportUpdateInstruction({
                        incomingSportsData: res.data,
                    });
                }
                s.setLastFetchTime();
            }
        }),

        updatePopularTournamentsInstruction: flow(function* fetch() {
            updatePopularTournamentsInstruction: {
                if (s.check.isUpdateCleanable) {
                    clearTimeout(window.__sportsUpdater);
                    break updatePopularTournamentsInstruction;
                }

                if (!s.check.canGetData) {
                    s.setUpdateForPopularTournaments();
                    break updatePopularTournamentsInstruction;
                }

                const res = yield s.fetchAndConvertDataForPopularTournaments() || {};

                if (!res || !res.success) {
                    s.unsuccessPopularTournamentsUpdateInstruction({res});
                } else {
                    s.successPopularTournamentsUpdateInstruction({
                        incomingData: res.data,
                    });
                }
                s.popularTournamentsLastFetchTime = new Date();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                     Update setter                                    ##
        // ##                                                                                      ##
        // ##========================================================================================

        setUpdateForSports({timeLeft} = {}) {
            if (!s.isWaitingUpdate && !getRoot(s).betting.bettingErrors.global) {
                s.setWaitingUpdate(true);
                clearTimeout(window.__sportsUpdater);
                window.__sportsUpdater = setTimeout(
                    () => s.updateSportInstruction(),
                    timeLeft ?? refreshTime.sports
                );
            }
        },

        setUpdateForPopularTournaments({timeLeft} = {}) {
            if (!getRoot(s).betting.bettingErrors.global) {
                clearTimeout(window.__popularTournamentsUpdater);
                window.__popularTournamentsUpdater = setTimeout(
                    () => s.updatePopularTournamentsInstruction(),
                    timeLeft ?? refreshTime.sports
                );
            }
        },
    }))
    .views((s) => ({
        activeSport() {
            const activeItems = getRoot(s).betting.activeItems;
            const sportId = activeItems.sports[activeItems.sports.length - 1];
            return s.sports.get(sportId);
        },

        activeCategory() {
            const activeItems = getRoot(s).betting.activeItems;
            const categoryId = activeItems.categories[activeItems.categories.length - 1];
            return s.activeSport()?.categories.get(categoryId);
        },

        activeTournament() {
            if (!getRoot(s).betting.activeItems.tournaments.length) return null;
            const tournamentId = getRoot(s).betting.activeItems.tournaments[0];
            return s.activeCategory()?.tournaments.get(tournamentId);
        },

        tournamentsWithTopFavoriteMatches(type = 'Top') {
            let tournaments = [];

            const tournamentList = (category) => {
                const tournamentsTopList = category?.tournamentsList.filter(
                    (tournament) => s[`tournaments${type}Ids`].includes(tournament.id)
                );
                tournaments = tournaments.concat(tournamentsTopList);
            };

            const categoryList = (sport) => {
                const language = getRoot(s).user.language;
                sortBy(
                    values(sport.categories).sort((a, b) =>
                        a.name
                            ?.toLowerCase()
                            .localeCompare(b.name?.toLowerCase(), language)
                    ),
                    ['order']
                ).map((category) => {
                    if (s[`categories${type}Ids`].includes(category.id)) {
                        tournamentList(category);
                    }
                });
            };

            sortBy(values(s.sports), [
                'order',
                (item) => {
                    return item?.name?.toLowerCase();
                },
            ]).map((sport) => {
                if (s[`sports${type}Ids`].includes(sport.id)) {
                    categoryList(sport);
                }
            });

            if (type === 'Top') {
                return orderBy(tournaments, 'countTotal', 'desc');
            }

            return tournaments;
        },

        get routerId() {
            return branchConfig[s.id].routerId || 1;
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                  Possibility checker                                 ##
        // ##                                                                                      ##
        // ##========================================================================================

        get check() {
            const betting = getRoot(s).betting;

            return {
                get canGetData() {
                    return getRoot(s).site?.status.isActive;
                },

                get isUpdateCleanable() {
                    let flag = s.id !== getRoot(s).betting.branchId;
                    if (!flag && this.isMobile) {
                        return !!betting.activeItems.categories.length;
                    }
                    return flag;
                },

                isMobile: getRoot(s).site.status.viewSize === 'mobile',
            };
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                   View sports list                                   ##
        // ##                                                                                      ##
        // ##========================================================================================

        get sportsList() {
            return sortBy(
                values(s.sports).reduce((a, sport) => {
                    return sport?.parentId ? a : [...a, sport];
                }, []),
                [
                    'order',
                    (item) => {
                        return item?.name?.toLowerCase();
                    },
                ]
            );
        },

        get popularTournamentsList() {
            return sortBy(
                values(s.popularTournaments),
                [
                    'order',
                ]
            );
        },
    }));

export default Branch;
