import {flow, types as t, getRoot, isAlive} from 'mobx-state-tree';
import {values} from 'mobx';

import MatchInstance from '../matches/instances/match';
import WithFlags from '../with-flags';
import liveMatchesFetches from './live-matches-actions/live-matches-actions';
import liveMatchesActions from './live-matches-actions/live-matches-fetches';
import {refreshTime, unsuccessCountLimit} from '../config';

const LiveMatches = t
    .model('LiveMatches', {
        matches: t.map(t.compose(MatchInstance, WithFlags)),
        liveMatchesCount: t.maybeNull(t.integer),
        activeLiveView: t.enumeration(['menu', 'detail']),
        isCalendar: false,
        liveCalendarSearchString: '',
        activeMatchExist: false,
    })
    .extend((s) => ({
        actions: {
            ...liveMatchesFetches(s),
            ...liveMatchesActions(s),
        },
    }))
    .actions((s) => ({
        deleteItem({id}) {
            s.matches.delete(id);
        },

        setActiveMatchExist(flag) {
            s.activeMatchExist = flag;
        },

        setLiveMatchesCount(count) {
            s.liveMatchesCount = count;
        },

        setActiveLiveView(view) {
            s.activeLiveView = view;
        },

        setCalendar(flag) {
            s.isCalendar = flag;
        },

        setLiveCalendarSearchString(str) {
            s.liveCalendarSearchString = str;
        },

        /**** ~~ This action used to set matches that cames from matches-v2 api ****/
        setMatch({match: m}) {
            if (!s.matches.has(m.id)) {
                s.matches.put({
                    id: m.id,
                    competitor1: m.competitor1,
                    competitor2: m.competitor2,
                    timeStart: m.timeStart,
                    oddsCount: m.oddsCount,
                    tv: m.tv,
                    matchScore: m.matchScore,
                    matchTime: m.matchTime ? m.matchTime.toString() : '',
                    shortStatusName: m.shortStatusName,
                    info: m.info ? JSON.parse(m.info) : [],
                    mainMarketGroupId: m.marketGroups[0].i,
                    mainMarketId: m.marketGroups[0].m[0].i,
                    order: m.order,
                    tournamentId: m.tournamentId,
                    categoryId: m.categoryId,
                    sportId: m.sportId,
                    betStatus: m.betStatus,
                    periodLength: m.periodLength,
                    lmt: m.lmt,
                    statusName: m.statusName,
                    statusId: m.statusId,
                    isStreaming: m.isStreaming,
                    streamingId: m.streamingId,
                    canCashout: m.canCashout,
                });

                const match = s.matches.get(m.id);

                if (m.marketGroups[0].m[0] && m.marketGroups[0].m[0].b) {
                    match.putUpdateMarketGroups({dataSource: m.marketGroups});
                } else if (m.marketGroups[0].m[0] && !m.marketGroups[0].m[0].b) {
                    const marketGroup = match.marketGroups.get(m.marketGroups[0].i);
                    const market = marketGroup?.markets.get(m.marketGroups[0].m[0].i);
                    if (market) {
                        market.removeItems();
                    }
                    if (marketGroup && !marketGroup.markets.size) {
                        match.deleteItem({id: marketGroup.id});
                        const marketGroups = match.marketGroupsList() ?? [];
                        if (marketGroups.length) {
                            getRoot(s).betting.activeItems.setActiveItem(
                                [marketGroups[0].id.toString()],
                                'marketGroups'
                            );
                        }
                    }
                }
            } else {
                s.matches.get(m.id).update(m);
            }
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Scenarios instructions                                ##
        // ##                                                                                      ##
        // ##========================================================================================

        unsuccessLiveMatchesInitializeInstruction({res, branchId}) {
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            getRoot(s).betting.branchId === branchId && getRoot(s).betting.setError({
                type: 'global',
                error,
                title: res.data?.error === 'SERVICE_CLOSED' ? 'Information Message' : null
            });
        },

        unsuccessUpdateLiveMatches({res, branchId}) {
            s.unsuccessCount++;
            if (s.unsuccessCount >= unsuccessCountLimit) {
                const error =
                    res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                        ? res.data?.note ?? res.data?.error
                        : 'CONNECTION_ISSUES_ERROR';

                getRoot(s).betting.branchId === branchId && getRoot(s).betting.setError({
                    type: 'global',
                    error,
                    title: res.data?.error === 'SERVICE_CLOSED' ? 'Information Message' : null
                });
            } else {
                s.setWaitingUpdate(false);
                s.setUpdateForLiveMatches();
            }
        },

        successLiveMatchesInitializeInstruction({data, branchId} = {}) {
            successLiveMatchesInitializeInstruction: {
                s.setUnsuccessCount(0);

                const betting = getRoot(s).betting;
                if (data && !Object.keys(data).length && betting.branchId === branchId) {
                    betting.setError({
                        type: 'global',
                        error: 'EMPTY_LIVE_BETS_ERROR',
                    });
                    s.setWaitingUpdate(false);
                    s.setUpdateForLiveMatches();
                    break successLiveMatchesInitializeInstruction;
                }

                s.setLiveMatchesData({dataSource: data});

                s.pushFirstEvent();
                s.setWaitingUpdate(false);
                s.setUpdateForLiveMatches();
                s.setLastFetchTime();
            }
        },

        successUpdateLiveMatchesInstruction({data, branchId} = {}) {
            successUpdateLiveMatchesInstruction: {
                s.setUnsuccessCount(0);
                if (data && !Object.keys(data).length && getRoot(s).betting.branchId === branchId) {
                    getRoot(s).betting.setError({
                        type: 'global',
                        error: 'EMPTY_LIVE_BETS_ERROR',
                    });
                    s.setWaitingUpdate(false);
                    s.setUpdateForLiveMatches();
                    break successUpdateLiveMatchesInstruction;
                } else {
                    getRoot(s).betting.clearError({type: 'global'});
                }

                s.setLiveMatchesData({dataSource: data});

                s.setWaitingUpdate(false);
                s.setUpdateForLiveMatches();
                s.setLastFetchTime();
            }
        },

        initializeLiveMatches: flow(function* fetch() {
            initializeLiveMatches: {
                if (isAlive(s)) {
                    const betting = getRoot(s).betting;
                    const branchId = betting.branchId;

                    if (
                        s.lastFetchTime &&
                        Date.now() <
                        +new Date(+s.lastFetchTime + refreshTime.liveMatches)
                    ) {
                        const timeLeft =
                            +new Date(+s.lastFetchTime + refreshTime.liveMatches) -
                            Date.now();
                        s.setWaitingUpdate(false);
                        s.setUpdateForLiveMatches({timeLeft});
                        s.pushFirstEvent();
                        betting.branch.setFetching({
                            type: 'initialFetching',
                            status: false,
                        });
                        betting.setInitialLoading(false);
                        s.setFetching({type: 'initialFetching', status: false});
                        break initializeLiveMatches;
                    }

                    s.setWaitingUpdate(true);
                    s.setFetching({type: 'initialFetching', status: true});

                    const res = yield s.fetchAndConvertDataForLiveMatches() || {};
                    if (!res || !res.success) {
                        s.unsuccessLiveMatchesInitializeInstruction({res, branchId});
                    } else {
                        s.successLiveMatchesInitializeInstruction({data: res.data, branchId});
                    }

                    betting.setFetching({
                        type: 'requestedDataLoading',
                        status: false,
                    });
                    betting.branch.setFetching({
                        type: 'initialFetching',
                        status: false,
                    });
                    betting.setInitialLoading(false);

                    s.setFetching({type: 'initialFetching', status: false});
                    s.lastFetchTime = Date.now();
                }
            }
        }),

        updateLiveMatches: flow(function* fetch() {
            updateLiveMatches: {
                if (isAlive(s)) {
                    if (s.check.isUpdateCleanable) {
                        s.setWaitingUpdate(false);
                        break updateLiveMatches;
                    }

                    if (!s.check.canGetData) {
                        s.setWaitingUpdate(false);
                        s.setUpdateForLiveMatches();
                        break updateLiveMatches;
                    }

                    s.setWaitingUpdate(true);
                    s.setFetching({type: 'isFetching', status: true});

                    const branchId = getRoot(s).betting.branchId;

                    const res = yield s.fetchAndConvertDataForLiveMatches() || {};
                    if (!res || !res.success) {
                        s.unsuccessUpdateLiveMatches({res, branchId});
                    } else {
                        s.successUpdateLiveMatchesInstruction({data: res.data, branchId});
                    }
                    s.setFetching({type: 'isFetching', status: false});
                    s.lastFetchTime = Date.now();
                }
            }
        }),

        setUpdateForLiveMatches({timeLeft} = {}) {
            if (!s.isWaitingUpdate && !getRoot(s).betting.bettingErrors.global) {
                s.setWaitingUpdate(true);
                clearTimeout(window.__liveMatchesUpdate);
                window.__liveMatchesUpdate = setTimeout(
                    () => s.updateLiveMatches(),
                    timeLeft ?? refreshTime.liveMatches
                );
            }
        },

        pushFirstEvent(forcePush = false) {
            const prematchPathParams = getRoot(s).router.prematchPathParams;
            const notFirstActiveMatch = s.matches.get(prematchPathParams.matchId);
            const url = s.getFirstLiveMatchUrl({notFirstActiveMatch, forcePush});

            if ((!prematchPathParams.sportId || (prematchPathParams.matchId && !notFirstActiveMatch) || forcePush) &&
                url &&
                (getRoot(s).betting.branchId === 'Live') &&
                getRoot(s).betting.sportMountStatus
            ) {
                if (url !== getRoot(s).router.location.pathname) {
                    getRoot(s).router.push(url)
                }
            }
        },

    }))
    .views((s) => ({
        get matchList() {
            return values(s.matches);
        },
        get mountedLiveMatchInstance() {
            return getRoot(s).betting.activeItems.matches.length
                ? s.matches.get(getRoot(s).betting.activeItems.matches[0])
                : null;
        },
        firstLiveMatch(branchId) {
            const branch = getRoot(s).betting.branches.get(branchId);
            const firstSport = branch.sportsList[0];
            const firstCategory = firstSport?.categoriesList[0];
            const firstTournament = firstCategory?.tournamentsList[0];
            return (firstTournament?.liveMatchesIds?.length) ? s.matches.get(firstTournament?.liveMatchesIds[0]) : null;
        },
        get check() {
            const betting = getRoot(s).betting;

            return {
                get canGetData() {
                    return betting.sportMountStatus && getRoot(s).site?.status.isActive;
                },

                get isUpdateCleanable() {
                    return getRoot(s).betting.branchId !== 'Live';
                },
            };
        },
        getFirstLiveMatchUrl({notFirstActiveMatch, forcePush} = {}) {
            let url;
            const betting = getRoot(s).betting;
            const branchId = betting.branchId;
            const firstLiveMatch = s.firstLiveMatch(branchId);

            if (firstLiveMatch) {
                if (getRoot(s).site.status.viewSize !== 'desktop' && (!notFirstActiveMatch || forcePush)) {
                    url = firstLiveMatch.tournamentUrl(branchId);
                } else {
                    url = firstLiveMatch.matchUrl(branchId);
                }
            }
            return url;
        }
    }));

export default LiveMatches;
