import {types, flow, getRoot} from 'mobx-state-tree';
import {values} from 'mobx';
import orderBy from 'lodash/orderBy';

import api from '../../../common/api';
import {imageLoading, llattempt, urlCleaner, getS3ImageUrl} from '../../../common/utils';
import {TIMERS} from '../../config';
import {llNotifier} from "../../../components";
import {urls} from "../../../configs";


const gamesTypeIconsConfig = {
    "BlackJacks": 'doruk-blackjack',
    "HTML5": '',
    "Jackpots": '',
    "New": '',
    "Other": 'doruk-other',
    "Popular": '',
    "Push Gaming": '',
    "Scratch Cards": '',
    "Slots": '',
    "Table games": '',
    "Video Poker": 'doruk-poker',
    "Virtual Sports": 'doruk-virtual',
    "Action": '',
    "Adventure": '',
    "Entertainment": '',
    "Classic Slots": '',
    "Video Slots": '',
    "Roulette": 'doruk-roulette',
    "Blackjack": 'doruk-blackjack',
    "Baccarat": 'doruk-baccarat',
    "Poker": 'doruk-poker',
    "Money Wheel": 'doruk-money-wheel',
    "Others": 'doruk-others',
    "Rulet": 'doruk-roulette',
    "Bingo": 'doruk-bingo',
    "Keno": 'doruk-keno',
    "Virtual": 'doruk-virtual',
    "Sic Bo": 'doruk-sic-bo',
    "Bet On Numbers": 'doruk-bet-on-numbers',
    "Low Stakes Blackjack": 'doruk-low-stakes-blackjack',
    "Racing": 'doruk-racing',
    "Soccer": 'sport_icon_font-1',
    "Darts": 'sport_icon_font-22',
    "Tennis": 'sport_icon_font-5',
};

const Banner = types.model('Banner', {
    id: types.identifierNumber,
    type: types.string,
    imgUrl: types.maybeNull(types.string),
    link: types.maybeNull(types.string),
});

const gameType = types.model('gameType', {
    name: types.string,
    type: types.string,
    link: types.maybeNull(types.string),
    icon: types.string,
    order: types.number,
});

const jackpotDetail = types.model('jackpotDetail', {
    currentValue: types.maybeNull(types.number),
    maxWinValue: types.maybeNull(types.number),
    lastWinValue: types.maybeNull(types.number),
    totalWins: types.maybeNull(types.number),
    lastWinDate: types.maybeNull(types.Date),
    maxWinDate: types.maybeNull(types.Date),
});

const jackpotGroupDetail = types.model('jackpotGroupDetail', {
    id: types.identifierNumber,
    currency: types.maybeNull(types.string),
    grand: types.optional(jackpotDetail, {}),
    major: types.optional(jackpotDetail, {}),
    minor: types.optional(jackpotDetail, {}),
    mini: types.optional(jackpotDetail, {}),
    lastFetchTime: types.maybeNull(types.Date),
})
    .actions((self) => ({
        setItem({item, data}) {
            self[item] = {
                currentValue: data.currentValue,
                maxWinValue: data.maxWinValue,
                lastWinValue: data.lastWinValue,
                totalWins: data.totalWins,
                lastWinDate: new Date(data.lastWinDate),
                maxWinDate: new Date(data.maxWinDate),
            };
        },
    }))
    .views((self) => ({

    }));

const JackpotGroup = types.model('JackpotGroup', {
    id: types.identifierNumber,
    name: types.string,
    bigImgLink: types.maybeNull(types.string),
    imgLink: types.maybeNull(types.string),
    background: types.maybeNull(types.string),
    hasBigImage: false,
    hasImage: false,
    hasBackground: false,
    order: types.number,
    gamesCount: types.number,
})
    .actions((self) => ({
        _setImgFlags(logo, big, back) {
            self.hasImage = logo;
            self.hasBigImage = big;
            self.hasBackground = back;
        },
        checkImgFlags: flow((function* fetch() {
            yield llattempt(
                () =>
                    Promise.all([
                        imageLoading(self.imgLink),
                        imageLoading(self.bigImgLink),
                        imageLoading(self.background),
                    ]).then((res) => {
                        self._setImgFlags(res[0], res[1], res[2]);
                    }),
                {
                    at: 'check jackpot ImgFlags',
                    withParams: {
                        imgLink: self.imgLink,
                        bigImgLink: self.bigImgLink,
                        background: self.background
                    },
                    withNotifier: false,
                }
            );
        })),
    }));

const Provider = types.model('Provider', {
    name: types.identifier,
    type: types.string,
    bigImgLink: types.maybeNull(types.string),
    imgLink: types.maybeNull(types.string),
    hasBigImage: false,
    hasImage: false,
    link: types.maybeNull(types.string),
    order: types.number,
    subGameGroups: types.array(gameType),
    jackpotGroups: types.map(JackpotGroup)
})
    .views((self) => ({
        getJackpotGroup(id) {
            return self.jackpotGroups.get(id);
        },
    }));

const SlotsCasinoGames = types
    .model('SlotsCasinoGames', {
        slotsProvider: types.map(Provider),
        casinoProvider: types.map(Provider),
        gamesViewType: types.array(types.string),
        gamesFilterByType: types.array(types.string),
        slotsFetchTime: types.maybeNull(types.Date),
        slotsBanners: types.map(Banner),
        casinoBanners: types.map(Banner),
        slotsBannersFetchTime: types.maybeNull(types.Date),
        casinoFetchTime: types.maybeNull(types.Date),
        casinoBannersFetchTime: types.maybeNull(types.Date),
        slotsFiltersLoaded: false,
        casinoFiltersLoaded: false,
        jackpotGroupsDetail: types.map(jackpotGroupDetail),
        jackpotGroupsDetailFetchTime: types.maybeNull(types.Date),
    })
    .actions((self) => {
        return {
            //INNER ACTIONS
            _addProvider: flow(function* fetch({item, subGameGroups, type}) {
                const newObj = {};
                newObj.name = item.BrandName;
                newObj.type = 'provider';
                newObj.link = `/${type}/provider=${item.BrandName}`;
                newObj.order = item.BrandOrder || item.SortOrder;

                newObj.bigImgLink = `${getS3ImageUrl() + '/images/brands/logo-big/' + item.logo}`;
                newObj.imgLink = `${getS3ImageUrl() + '/images/brands/logo-small/' + item.smallLogo}`;

                yield llattempt(
                    () =>
                        Promise.all([
                            imageLoading(newObj.imgLink),
                            imageLoading(newObj.bigImgLink),
                        ]).then((res) => {
                            newObj.hasImage = res[0];
                            newObj.hasBigImage = res[1];
                            self._setProvider({item: newObj, type, subGameGroups});
                        }),
                    {
                        at: 'check for provider img',
                        withParams: {
                            imgLink: newObj.imgLink,
                            bigImgLink: newObj.bigImgLink,
                        },
                        withNotifier: false,
                    }
                );
            }),
            _setFiltersLoaded(flag, gamesType) {
                self[gamesType + 'FiltersLoaded'] = flag;
            },
            _setProvider({item, type, subGameGroups}) {
                self[type + 'Provider'].set(item.name, item);
                subGameGroups.map(group => {
                    self[type + 'Provider'].get(item.name).subGameGroups.push(group);
                })
            },
            _setProviderJackpotGroup({id, type, group}) {
                const provider = self[type + 'Provider'].get(id);
                if (provider) {
                    provider.jackpotGroups.put(group);
                    const jackpotGroup = provider.jackpotGroups.get(group.id);
                    if (jackpotGroup) {
                        jackpotGroup.checkImgFlags();
                    }
                }
            },
            _setJackpotGroupsDetail(data) {
                data.forEach(item => item.jackpotGroups.forEach(group => {
                    self.jackpotGroupsDetail.put({
                        id: group.jackpotId,
                        currency: group.jackpotCurrency,
                    });
                    const jackpotGroup= self.jackpotGroupsDetail.get(group.jackpotId);
                    Object.keys(group.jackpotData).forEach(key => {
                        jackpotGroup.setItem({item: key, data: group.jackpotData[key]});
                    })
                }))
            },

            //=================EXTERNAL ACTIONS=================
            getBanners: flow(function* fetch({gamesType}) {
                const fetchTime = gamesType === 'slots' ? self.slotsBannersFetchTime : self.casinoBannersFetchTime;

                if (!fetchTime || new Date() - fetchTime > TIMERS.slotsCasinoFetchTimer) {
                    const response = yield llattempt(
                        () => api.games.getBanner({}, gamesType === 'slots' ? 'slot' : 'casino'), {
                            msg:
                                'Cant load' + gamesType === 'slots'
                                    ? 'slot banner'
                                    : 'casino banner',
                            at: 'SlotsCasinos',
                            withParams: {},
                            withNotifier: false,
                        });

                    if (response.success) {
                        self.clearBanners(gamesType);
                        Promise.all(
                            response.data.map((banner) => {
                                const typeTempArr = banner.type.split('_');
                                if (banner?.image) {
                                    return imageLoading(getS3ImageUrl() + '/images/banners/' + getRoot(self).user.customerGroup + '/' + banner.image)
                                        .then((res) => {
                                        if (res) {
                                            self.setBanners(
                                                gamesType,
                                                {
                                                    id: banner.id,
                                                    type: typeTempArr[1],
                                                    imgUrl: getS3ImageUrl() + '/images/banners/' + getRoot(self).user.customerGroup + '/' + banner.image,
                                                    link: urlCleaner(
                                                        banner.url
                                                    ),
                                                }
                                            );
                                        }
                                    });
                                }
                            })).then(() => {
                            self.setFetchTime(gamesType)
                        });
                    }
                }
            }),
            getFilters: flow(function* fetch({gamesType}) {
                const requestFields = 'BrandName,BrandOrder,logo,smallLogo,SubGroupOrder,SubGameGroupKey,SubGameGroup'
                    + (gamesType === 'slots' ?
                            ',JackpotId,JackpotName,JackpotOrder,JackpotLogoBig,JackpotLogoSmall,JackpotBackground'
                            : ''
                    );
                const fetchTime =
                    gamesType === 'slots' ? self.slotsFetchTime : self.casinoFetchTime;
                if (!fetchTime || (new Date() - fetchTime > TIMERS.slotsCasinoFetchTimer)) {
                    self._setFiltersLoaded(false, gamesType);
                    const response = yield llattempt(
                        () =>
                            api.games.getFilters({
                                type: gamesType === 'slots' ? 'slot' : 'casino',
                                filters: [requestFields],
                            }),
                        {
                            msg: 'GETTING_ERROR_PROVIDERS_TYPES',
                            at: 'games.getFilters',
                            withParams: {},
                        }
                    );
                    if (!response.success) return false;
                    self[gamesType + 'Provider'].clear();
                    self[gamesType + 'GameType'] = [];
                    const arrayAddProviderPromises = [];
                    const subGameGroupByProvider = {};
                    Object.values(response.data[requestFields]).map(item => {
                        if (!subGameGroupByProvider[item.BrandName]) {
                            subGameGroupByProvider[item.BrandName] = []
                        }
                        if (item.GameType?.length) {
                            if (!subGameGroupByProvider[item.BrandName].find(group => group.name === item.GameType.SubGameGroup)) {
                                item.GameType.map((el) => {
                                    const newObj = {};
                                    newObj.name = el.SubGameGroup;
                                    newObj.icon = gamesTypeIconsConfig[el.SubGameGroupKey]
                                        ? gamesTypeIconsConfig[el.SubGameGroupKey]
                                        : 'llg-poker-chip';
                                    newObj.type = 'gameType';
                                    newObj.link = `/${gamesType}/gameType=${el.SubGameGroup}`;
                                    newObj.order = el.SubGroupOrder;
                                    subGameGroupByProvider[item.BrandName].push(newObj);
                                })
                            }
                        }
                    })
                    orderBy(response.data[requestFields], 'BrandOrder', 'asc')
                        .map((item) => {
                            arrayAddProviderPromises.push(self._addProvider({
                                item,
                                subGameGroups: subGameGroupByProvider[item.BrandName],
                                type: gamesType
                            }));
                        });
                    Promise.all(arrayAddProviderPromises).then(() => {
                        Object.values(response.data[requestFields]).map(item => {
                            if (item.JackpotGroups) {
                                item.JackpotGroups?.forEach(groupItem => {
                                    const group = {
                                        id: groupItem.JackpotId,
                                        name: groupItem.JackpotName,
                                        bigImgLink: groupItem.JackpotLogoBig ? `${getS3ImageUrl()}/images/jackpots/logo-big/${groupItem.JackpotLogoBig}` : null,
                                        imgLink: groupItem.JackpotLogoSmall ? `${getS3ImageUrl()}/images/jackpots/logo-small/${groupItem.JackpotLogoSmall}` : null,
                                        background: groupItem.JackpotBackground ? `${getS3ImageUrl()}/images/jackpots/background/${groupItem.JackpotBackground}` : null,
                                        order: groupItem.JackpotOrder,
                                        gamesCount: groupItem.gamesCount
                                    };
                                    self._setProviderJackpotGroup({id: item.BrandName, type: gamesType, group});
                                })
                            }
                        });
                        self._setFiltersLoaded(true, gamesType);
                    });
                    self[gamesType + 'FetchTime'] = new Date();
                }
            }),

            getJackpotDetails: flow(function* fetch({jackpotId} = {}) {
                if (!self.slotsFiltersLoaded || self.jackpotGroupsList.length) {
                    const jackpotGroupDetail = self.jackpotGroupsDetail.get(+jackpotId);

                    const fetchTime = jackpotId ? jackpotGroupDetail?.lastFetchTime : self.jackpotGroupsDetailFetchTime;

                    if (
                        !fetchTime ||
                        Date.now() < +new Date(+fetchTime + 10000)
                    ) {
                        const params = jackpotId ? {ids: [jackpotId]} : {};
                        const response = yield llattempt(
                            () =>
                                api.games.jackpotDetails(params),
                            {
                                msg: 'getJackpotDetails',
                                at: 'games.getJackpotDetails',
                                withParams: params,
                                withNotifier: false,
                            }
                        );

                        if (response.success) {
                            self._setJackpotGroupsDetail(response.data);
                        }
                    }
                }
            }),

            setViewType(type, value = null) {
                self.gamesViewType = type;
                self.gamesFilterByType = value;
            },
            setBanners(type, item) {
                self[type + 'Banners'].set(item.id, item);
            },
            clearBanners(type) {
                self[type + 'Banners'].clear();
            },
            setBannersFetchTime(type, date = new Date()) {
                self[type + 'BannersFetchTime'] = date;
            },
            resetFetchTime() {
                self.casinoFetchTime = null;
                self.slotsFetchTime = null;
                self.slotsBannersFetchTime = null;
                self.casinoBannersFetchTime = null;
            },
            setFetchTime(type) {
                self[type + 'BannersFetchTime'] = Date.now();
            },
            favoriteToggle: flow(function* fetch({gamesType, item, isFavoriteGame, setIsFavoriteGame}) {
                const response = yield llattempt(
                    () => api.games.favoriteGame({
                        isLive: gamesType === "slots" ? 0 : 1,
                        gameId: item.iD,
                        del: !!isFavoriteGame
                    }), {
                        withParams: {},
                        withNotifier: false,
                    });
                if (response.success) {
                    setIsFavoriteGame(!isFavoriteGame);
                } else {
                    llNotifier({
                        message: 'GENERAL_ERROR',
                        type: 'error',
                    });
                }

            }),
        };
    })
    .views((self) => ({
        providerList({type, subGameGroup = null}) {
            let list = orderBy(values(self[type + 'Provider']), ['order', 'name'], 'asc');
            if (subGameGroup) {
                return list.reduce((acc, item) => {
                    if (item.subGameGroups.find(group => group.name === subGameGroup)) {
                        return [...acc, item];
                    } else {
                        return [...acc];
                    }
                }, [])
            } else {
                return list;
            }
        },
        providerHasSubGameGroups({type, provider = null}) {
            return !provider || self[type + 'Provider'].get(provider)?.subGameGroups?.length;
        },
        gameTypeList({type, provider = null}) {
            if (provider) {
                return orderBy(self[type + 'Provider'].get(provider)?.subGameGroups, ['order'], ['asc']);
            } else {
                const groups = [];
                values(self[type + 'Provider']).map(provider => {
                    provider.subGameGroups.map(item => {
                        if (!groups.find(group => group.name === item.name)) {
                            groups.push(item)
                        }
                    })
                })
                return orderBy(groups, ['order'], ['asc']);
            }
        },
        bannersList(type, platform) {
            return values(self[type + 'Banners']).find(banner => banner.type === platform);
        },
        getProvider(type, id) {
            return self[type + 'Provider']?.get(id) ?? {};
        },
        get presetFilters() {
            const filters = {
                currentView: self.gamesViewType,
                activeProvider: '',
                activeType: '',
                search: '',
            }
            if (self.gamesFilterByType.length) {
                if (self.gamesViewType.includes('gameType')) {
                    filters.currentView = self.gamesViewType;
                    filters.activeType = self.gamesFilterByType[self.gamesViewType.indexOf('gameType')];
                    filters.activeProvider = self.gamesFilterByType[self.gamesViewType.indexOf('provider')] ?? '';
                    return filters;
                }
                if (self.gamesViewType.includes('provider')) {
                    filters.currentView = self.gamesViewType;
                    filters.activeProvider = self.gamesFilterByType[self.gamesViewType.indexOf('provider')];
                    filters.activeType = self.gamesFilterByType[self.gamesViewType.indexOf('gameType')] ?? '';
                    return filters;
                }
                if (self.gamesViewType.includes('search')) {
                    filters.currentView = ['search'];
                    filters.search = self.gamesFilterByType[0];
                    return filters;
                }
                return filters;
            } else {
                if (self.gamesViewType.includes('jackpots')) {
                    filters.activeJackpot = getRoot(self).router.gamesPathParams.jackpotId;
                    return filters;
                }
                return filters;
            }
        },
        get jackpotGroupsList() {
            return self.slotsFiltersLoaded ?
                orderBy(values(self.slotsProvider).reduce((acc, provider) => {
                    if (provider.jackpotGroups.size) {
                        values(provider.jackpotGroups).forEach(group => {
                            if (!acc.find(item => item.id === group.id)) {
                                acc = [...acc, {...group, brand: provider.name}]
                            }
                        })
                    }
                    return acc
                }, []), ['order'], ['asc'])
                : [];
        },
        get jackpotGroupsListByProvider() {
            const brandsArr = [];
            let newArr = [];
            if (self.slotsFiltersLoaded) {
                self.jackpotGroupsList.forEach(group => {
                    if (!brandsArr.includes(group.brand)) {
                        brandsArr.push(group.brand);
                        const newGroupsByBrand = self.jackpotGroupsList.filter(item => item.brand === group.brand);
                        if (newGroupsByBrand?.length) {
                            newArr = [...newArr, ...newGroupsByBrand];
                        }
                    }
                })
            }
            return newArr;
        },
        getJackpotGroupDetail({id}) {
            return self.jackpotGroupsDetail.get(id)
        },
        providerJackpots({name}) {
            const provider = self.slotsProvider.get(name);
            if (provider?.jackpotGroups.size) {
                return orderBy(values(provider.jackpotGroups), ['order'], ['asc']);
            } else {
                return [];
            }
        },
    }));

export default SlotsCasinoGames;
