import React, {useEffect, useRef, useState} from "react";
import cx from "classnames";
import throttle from "lodash/throttle";

import {LLPreloader, LLScrollBox, withCommon} from "../../../../components";
import {GameImgLoader} from "../img-loader";
import {FilterSortSearch} from "../filter-sort-search";
import {llattempt} from "../../../../common/utils";
import api from "../../../../common/api";
import cssVars from '../../../../styles/themes/theme/variables.scss';


const CREATE_BATTLE_GAME_ITEM_WIDTH = {
    mobile:
        +cssVars.css_sizeWidth_slotsBattlesGame_mobile +
        +cssVars.css_sizeMargin_slotsBattlesGame_mobile,
    tablet:
        +cssVars.css_sizeWidth_slotsBattlesGame_desktop +
        +cssVars.css_sizeMargin_slotsBattlesGame_desktop,
    desktop:
        +cssVars.css_sizeWidth_slotsBattlesGame_desktop +
        +cssVars.css_sizeMargin_slotsBattlesGame_desktop,
};

const setNewScrollPosition = throttle((setScrollPosition, h) => {
    setScrollPosition(h);
}, 100);


export const CreateBattleGamesList = withCommon({
    store: ({store}) => ({
        slotBattlesProviderList: store.slotsBattles.slotBattlesProviderList,
        provider: store.slotsBattles.slotBattlesChosenProvider,
        sortby: store.slotsBattles.slotBattlesChosenSortby,
        search: store.slotsBattles.slotBattlesSearch,
        viewSize: store.site.status.viewSize,
    }),
    defaultName: "CreateBattleGamesList",
    isObserver: true,
    isTrans: true
})(({
        gameChooseIsActive,
        setGameChooseIsActive,
        setSelectedGame,
        provider,
        sortby,
        search,
        viewSize,
        trans,
    }) => {

    const [initialLoading, setInitialLoading] = useState(true); //loading for render and change activeItem
    const [games, setGames] = useState([]); //received games

    const [itemsInRow, setItemsInRow] = useState(0); //items in row
    const [loading, setLoading] = useState(true); //loading on scroll
    const [loadedGamesCount, setLoadedGamesCount] = useState(0); //total received games count after render or change activeItem
    const [allGamesLoaded, setAllGamesLoaded] = useState(false); //stop load more games on scroll event if we received games count less than we asked
    const [scrollPosition, setScrollPosition] = useState(0); //stop load more games on scroll event if we received games count less than we asked
    const [prevScrollPosition, setPrevScrollPosition] = useState(0); //stop load more games on scroll event if we received games count less than we asked

    const gamesWrapperRef = useRef();
    const gamesListRef = useRef();

    useEffect(() => {
        itemsInRow && loadMoreGames()
    }, [itemsInRow]);

    useEffect(() => {
        if (scrollPosition) {
            const gameListHeight = gamesListRef.current.offsetHeight;
            const gameListWrapperHeight = gamesWrapperRef.current.offsetHeight;

            if ((scrollPosition + gameListWrapperHeight) >= gameListHeight) {
                setPrevScrollPosition(prevScrollPosition + scrollPosition);
                loadMoreGames(false)
            }
        }
    }, [scrollPosition]);

    useEffect(() => {
        if (loading) {
            setItemsInRow(Math.trunc(
                gamesWrapperRef.current.offsetWidth / CREATE_BATTLE_GAME_ITEM_WIDTH[viewSize]
            ));
            loadMoreGames();
        }
    }, [loading]);

    useEffect(() => {
        setLoading(true);
        setInitialLoading(true);
        setGames([]);
        setLoadedGamesCount(0);
        setAllGamesLoaded(false);
        setScrollPosition(0);
        setPrevScrollPosition(0);
    }, [provider, sortby, search]);

    useEffect(throttle(() => {
        !loading && setItemsInRow(Math.trunc(
            gamesWrapperRef.current.offsetWidth / CREATE_BATTLE_GAME_ITEM_WIDTH[viewSize]
        ));
    }, 300), [window.innerWidth]);

    const loadMoreGames = (isRowMissingItems = true) => {
        if (!allGamesLoaded) {
            let take = viewSize !== 'desktop' ? 40 : itemsInRow * 5; //game count for request

            if (isRowMissingItems && loadedGamesCount) {
                //needed to recalculate asked item count on resize event
                if (take > loadedGamesCount) {
                    take = take - loadedGamesCount;
                } else if (take < loadedGamesCount) {
                    // take = itemsInRow - ((loadedGamesCount - take) % itemsInRow);
                } else {
                    take = 0;
                }
            }

            const params = {
                skip: loadedGamesCount,
                take: take,
                sort: sorting(),
                filter: ['isBattleAllowed', '=', '1'],
                select: [
                    "BrandName",
                    "BrandOrder",
                    "Created",
                    "GameHeight",
                    "GameID",
                    "GameName",
                    "GameThumbnail",
                    "GameUrl",
                    "GameWidth",
                    "ID",
                    "Param1",
                    "Provider",
                    "ProviderID",
                    "SortOrder",
                    "SubGameGroup",
                    "SubGameGroupKey",
                    "SubGroupOrder",
                    "thumbnail",
                    "jbet"
                ],
            };

            if (provider) {
                params.filter = [[...params.filter], ['BrandName', '=', provider]]
            }
            if (search) {
                params.filter = [[...params.filter], ['GameName', 'contains', search]]
            }

            if (take !== 0) {
                setLoading(true);
                llattempt(
                    () =>
                        api.games
                            .getBattleGames({
                                type: 'slot',
                                params: JSON.stringify(params),
                            })
                            .then((result) => {
                                if (result.success) {
                                    games.length
                                        ? setGames(games.concat(result.data.data))
                                        : setGames(result.data.data);
                                    setLoadedGamesCount(
                                        loadedGamesCount + result.data?.data?.length
                                    );
                                    result.data?.data?.length < take && setAllGamesLoaded(true);
                                } else {
                                    setAllGamesLoaded(true);
                                }
                                setLoading(false);
                                setInitialLoading(false);
                            }),
                    {
                        msg: 'Cant load games',
                        at: 'SlotsCasinosGamesList.loadMoreGames',
                        withParams: {
                            type: 'slot',
                            params: params,
                        },
                        onError: () => {
                            setLoading(false);
                            setInitialLoading(false);
                            setAllGamesLoaded(true);
                        },
                        withNotifier: false,
                    }
                );
            } else {
                setLoading(false);
                setInitialLoading(false);
            }
        }
    };

    const sorting = () => {
        switch (sortby) {
            case 'Z - A':
                return [
                    {selector: 'GameName', desc: true},
                    {selector: 'SortOrder', desc: false},
                ];
            case 'Oldest':
                return [
                    {selector: 'Created', desc: false},
                    {selector: 'SortOrder', desc: false},
                    {selector: 'GameName', desc: false},
                ];
            case 'Newest':
                return [
                    {selector: 'Created', desc: true},
                    {selector: 'SortOrder', desc: false},
                    {selector: 'GameName', desc: false},
                ];
            case 'A - Z':
            default:
                return [
                    {selector: 'GameName', desc: false},
                    {selector: 'SortOrder', desc: false},
                ];
        }
    };

    return (
        <div className="create-battle-games-list" ref={gamesWrapperRef}>
            <div className="create-battle-games-list__header">
                <div className="create-battle-games-list__header-left">
                    <div className="create-battle-games-list__close">
                        <button className={"create-battle-games-list__close-btn"}
                                onClick={() => setGameChooseIsActive(!gameChooseIsActive)}
                        >
                            <i className={"doruk-close"}/>
                        </button>
                    </div>
                </div>
                <div className="create-battle-games-list__header-right">
                    <FilterSortSearch type="details"/>
                </div>
            </div>

            {initialLoading ?
                <div className="create-battle-games-list__init-loading">
                    <LLPreloader/>
                </div>
                :
                <>
                    <LLScrollBox
                        id={"slots-battles__scrollbox"}
                        onScroll={(h) => setNewScrollPosition(setScrollPosition, h)}
                        customClasses={cx("create-battle-games-list__body ll-dropdown-scrollbox")}
                    >
                        <div className="create-battle-games-list__inner" ref={gamesListRef}>
                            {games.length ? games.map((game) => (
                                    <div key={game.ID} className="create-battle-games-list__games-item"
                                         onClick={() => {
                                             setSelectedGame(game);
                                             setGameChooseIsActive(false);
                                         }}
                                    >
                                        <GameImgLoader item={game}/>
                                    </div>
                                ))
                                :
                                <p className="games-no-data__text">{trans('NO_GAMES_FOR_BATTLES')}</p>
                            }
                        </div>
                    </LLScrollBox>
                    {loading && (
                        <LLPreloader className="slots__games-loader"/>
                    )}
                </>
            }
        </div>
    );
});