import React, {useState, useEffect, createContext} from 'react';
import {inject, observer} from "mobx-react";

import {LLPreloader, LLElementTypeSetter} from '../../components';
import {parseUrl, trans} from '../../common/utils';
import SlotsCasinosGamesMenu from './components/games-menu/games-menu';
import SlotsCasinosGamesList from './components/games-list';
import config from '../../static/themes/config';


const initFiltering = {
    select: {
        slots: [
            "BrandName",
            "BrandOrder",
            "Created",
            "GameHeight",
            "GameID",
            "GameName",
            "GameThumbnail",
            "GameUrl",
            "GameWidth",
            "ID",
            "IsPopular",
            "IsPromoted",
            "FavoriteOrder",
            "Param1",
            "Provider",
            "ProviderID",
            "SortOrder",
            "SubGameGroup",
            "SubGameGroupKey",
            "SubGroupOrder",
            "hasFunMode",
            "LastLaunchTime",
            "thumbnail",
            "jackpotId",
            "JackpotOrder"
        ],
        casino: [
            "BrandName",
            "BrandOrder",
            "Created",
            "GameHeight",
            "GameID",
            "GameName",
            "GameThumbnail",
            "GameType",
            "GameUrl",
            "GameWidth",
            "ID",
            "IsPopular",
            "IsPromoted",
            "LimitMax",
            "LimitMin",
            "FavoriteOrder",
            "Param1",
            "Provider",
            "ProviderID",
            "SortOrder",
            "SubGameGroup",
            "SubGameGroupKey",
            "SubGroupOrder",
            "LastLaunchTime",
            "thumbnail"
        ]
    },
    filter: ['IsFeatured', '=', '1'],
    sort: [
        {selector: 'SortOrder', desc: false},
        {selector: 'GameName', desc: false},
    ],
};

export const GamesContext = createContext();

const SlotsCasinos = ({store, gamesType}) => {
    const [bannersLoading, setBannersLoading] = useState(true);
    const [filtersLoading, setFiltersLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [activeGameMenuItem, setActiveGameMenuItem] = useState('');
    const [isSubMenuVisible, setSubMenuVisible] = useState(false);
    const [activeSubSubMenuItem, setActiveSubSubMenuItem] = useState();
    const [filtering, setFiltering] = useState({...initFiltering, select: initFiltering.select[gamesType]});
    const [mode, setMode] = useState(0);

    const
        user = store.user,
        viewSize = store.site.status.viewSize,
        siteName = store.site.siteName,
        slotsCasinoGames = store.slotsCasinoGames,
        presetFilters = store.slotsCasinoGames.presetFilters,
        router = store.router;

    const gamesMenu =
        viewSize === 'mobile'
            ? config(siteName).slotsCasinoMobileMenu
            : config(siteName).slotsCasinoMenu;

    /********* STATE SETTERS *************/
    const setSorting = (sort = null) => {
        setFiltering({
            ...filtering,
            sort: sort ?? [
                {selector: 'SortOrder', desc: false},
                {selector: 'GameName', desc: false},
            ],
        });
    };

    const resetFiltering = () => router.push(`/${gamesType}`);

    const searchGames = (val) => {
        if (val.length > 1) {
            router.push(`/${gamesType}/search=${val}`);
        }
    };

    const setMultiFilter = ({provider, gameType}) => {
        if (provider && gameType) {
            return [['BrandName', '=', provider], ['SubGameGroup', '=', gameType]];
        }
        if (provider) {
            return ['BrandName', '=', provider];
        }
        if (gameType) {
            return ['SubGameGroup', '=', gameType];
        }
    };

    /****************** ***********************/
    useEffect(() => {
        setSubMenuVisible(true);
        if (presetFilters.currentView.includes('provider') || presetFilters.currentView.includes('gameType')) {
            let sortArr = [
                {selector: 'SortOrder', desc: false},
                {selector: 'GameName', desc: false},
            ];
            if (presetFilters.activeProvider) {
                sortArr = [{selector: 'Created', desc: true}, ...sortArr];
            }
            sortArr = [{selector: 'IsPromoted', desc: true}, ...sortArr];
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: setMultiFilter({
                    provider: presetFilters.activeProvider,
                    gameType: presetFilters.activeType
                }),
                sort: sortArr
            });
        }
        if (presetFilters.currentView.includes('search')) {
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: ['GameName', 'contains', presetFilters.search],
                sort: [
                    {selector: 'SortOrder', desc: false},
                    {selector: 'GameName', desc: false},
                ],
            });
            return true;
        }
        if (presetFilters.currentView.includes('new')) {
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: ['GameName', '<>', ''],
                sort: [
                    {selector: 'Created', desc: true},
                    {selector: 'SortOrder', desc: false},
                    {selector: 'GameName', desc: false},
                ],
            });
            return true;
        }
        if (presetFilters.currentView.includes('popular')) {
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: ['GameName', '<>', ''],
                sort: [
                    {selector: 'LaunchCnt', desc: true},
                    {selector: 'SortOrder', desc: false},
                    {selector: 'GameName', desc: false},
                ],
            });
        }
        if (presetFilters.currentView.includes('featured')) {
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: ['IsFeatured', '=', '1'],
                sort: [
                    {selector: 'SortOrder', desc: false},
                    {selector: 'GameName', desc: false},
                ],
            });
        }
        if (presetFilters.currentView.includes('favorite')) {
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: [['GameName', '<>', ''], ['FavoriteOrder', '>', '0']],
                sort: [
                    {selector: 'FavoriteOrder', desc: true},
                ],
            });
        }
        if (presetFilters.currentView.includes('recently-played')) {
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: [['GameName', '<>', ''], ['LastLaunchTime', '<>', null]],
                sort: [
                    {selector: 'LastLaunchTime', desc: true},
                ],
            });
        }
        if (presetFilters.currentView.includes('jackpots')) {
            setFiltering({
                select: initFiltering.select[gamesType],
                filter: ['GameName', '<>', ''],
                sort: [
                    {selector: 'Created', desc: true},
                    {selector: 'SortOrder', desc: false},
                    {selector: 'GameName', desc: false},
                ],
            });
            return true;
        }
    }, [JSON.stringify(slotsCasinoGames.gamesViewType), JSON.stringify(slotsCasinoGames.gamesFilterByType)]);

    /********* DATA LOADING ON RENDER ON LOGIN/SIGN OUT ************/
    useEffect(() => {
        setBannersLoading(true);
        setFiltersLoading(true);

        slotsCasinoGames
            .getFilters({gamesType})
            .then(() => setFiltersLoading(false));
        slotsCasinoGames.getBanners({gamesType}).then(() => setBannersLoading(false));
    }, [user.isLogged]);
    /****************** ***********************/

    const banner = slotsCasinoGames.bannersList(
        gamesType,
        viewSize === 'mobile' ? 'mobile' : 'desktop'
    );

    /******************** RENDER *********************/
    return (
        <GamesContext.Provider value={{
            mode,
            setMode,
            searchGames,
            gamesType,
            filtering,
            resetFiltering,
            setSorting,
            activeGameMenuItem,
            setActiveGameMenuItem,
            activeSubSubMenuItem,
            setActiveSubSubMenuItem,
            router,
        }}>
            <div className={`ll-page ${gamesType} ${gamesType}--${viewSize}`}>
                {bannersLoading || filtersLoading ? (
                    <div className="slots-casino--loader-container">
                        <LLPreloader/>
                    </div>
                ) : !pageError ? (
                    <>
                        <div className={`games__header`}>
                            {banner?.imgUrl ?
                                <LLElementTypeSetter
                                    className={`games__header-banner`}
                                    {...parseUrl(banner.link, user).elementAttributes}
                                    onClick={parseUrl(banner.link, user).someClick}
                                    style={{backgroundImage: `url(${banner.imgUrl})`,}}
                                />
                                :
                                <div className="games__header-banner"/>
                            }
                            <SlotsCasinosGamesMenu
                                gamesType={gamesType}
                                currentSorting={filtering.sort}
                                setSorting={setSorting}
                                resetFiltering={resetFiltering}
                                gamesMenu={gamesMenu}
                                setPageError={setPageError}
                                activeGameMenuItem={activeGameMenuItem}
                                setActiveGameMenuItem={setActiveGameMenuItem}
                                activeSubSubMenuItem={activeSubSubMenuItem}
                                setActiveSubSubMenuItem={setActiveSubSubMenuItem}
                                isSubMenuVisible={isSubMenuVisible}
                                setSubMenuVisible={setSubMenuVisible}
                            />
                        </div>
                        <SlotsCasinosGamesList
                            filtering={filtering}
                            gamesType={gamesType}
                            currentView={presetFilters.currentView}
                            setPageError={setPageError}
                            activeProvider={presetFilters.activeProvider}
                            activeJackpot={presetFilters.activeJackpot}
                            activeGameMenuItem={activeGameMenuItem}
                            setActiveGameMenuItem={setActiveGameMenuItem}
                            activeSubSubMenuItem={activeSubSubMenuItem}
                            setActiveSubSubMenuItem={setActiveSubSubMenuItem}
                        />
                    </>
                ) : (
                    <p>{trans('Sorry, this page are not available now')}</p>
                )}
            </div>
        </GamesContext.Provider>
    );
};

export default inject('store')(observer(SlotsCasinos));
