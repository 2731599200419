import { getRoot } from 'mobx-state-tree';

import { refreshTime } from '../../config';


const liveMatchesActions = (s) => ({
    setMatchCount(sum) {
        s.totalMatchCount = sum;
    },

    convertLiveMatch({ match: m }) {
        return {
            id: m.id,
            competitor1: {
                id: m.c1.cid,
                name: m.c1.nm,
            },
            competitor2: {
                id: m.c2.cid,
                name: m.c2.nm,
            },
            order: m.o,
            oddsCount: m.oc,
            tv: !!m.tv,
            timeStart: m.ts.replace(' ', 'T') + '+03:00',
            sportId: m.sportId,
            categoryId: m.categoryId,
            tournamentId: m.tournamentId,
            matchScore: m.ms,
            matchTime: m.mt,
            info: m.info,
            shortStatusName: m.ss,
            marketGroups: [{ ...m.mg, m: [m.mg.m] }],
            periodLength: m.pl,
            lmt: !!m.lmt,
            statusName: m.s,
            statusId: m.si,
            isStreaming: m.st === 1,
            streamingId: m.sti,
            canCashout: !!m.mg.m.coa,
        };
    },

    setLiveMatchesData({ dataSource = {} } = {}) {
        const activeItems = getRoot(s).betting.activeItems;
        const branch = getRoot(s).betting.branches.get('Live');
        let liveMatchesCount = 0;
        s.setActiveMatchExist(false);

        Object.keys(dataSource).map((sportId) => {
            const sportInstance = {
                id: sportId,
                name: dataSource[sportId].n,
                order: dataSource[sportId].o,
            };
            branch.putUpdateData({ dataSource: [sportInstance] });

            const sport = branch.sports.get(sportId);
            let sportsLiveMatchesCount = 0;
            Object.keys(dataSource[sportId].c).map((categoryId) => {
                const categoryInstance = {
                    id: categoryId,
                    name: dataSource[sportId].c[categoryId].n,
                    order: dataSource[sportId].c[categoryId].o,
                };
                sport.putUpdateData({ dataSource: [categoryInstance] });

                const category = sport.categories.get(categoryId);
                Object.keys(dataSource[sportId].c[categoryId].t).map((tournamentId) => {
                    const tournamentInstance = {
                        id: tournamentId,
                        name: dataSource[sportId].c[categoryId].t[tournamentId].n,
                        order: dataSource[sportId].c[categoryId].t[tournamentId].o,
                    };
                    category.putUpdateData({ dataSource: [tournamentInstance] });

                    const tournament = category.tournaments.get(tournamentId);
                    const ids = [];
                    Object.keys(dataSource[sportId].c[categoryId].t[tournamentId].m).map(
                        (matchId) => {
                            if (activeItems.isActive({ id: matchId, type: 'matches' })) {
                                s.setActiveMatchExist(true);
                            }
                            const match = s.convertLiveMatch({
                                match: {
                                    id: matchId,
                                    ...dataSource[sportId].c[categoryId].t[tournamentId]
                                        .m[matchId],
                                },
                            });
                            ids.push(matchId);
                            getRoot(s).betting.liveMatches.setMatch({
                                match: { ...match, sportId, categoryId, tournamentId },
                            });
                        }
                    );
                    tournament.setEventsIds({ type: 'liveMatches', ids });
                    liveMatchesCount = liveMatchesCount + ids.length;
                    sportsLiveMatchesCount = sportsLiveMatchesCount + ids.length;
                });
                category.deleteOldTournaments({
                    tournamentIds: Object.keys(dataSource[sportId].c[categoryId].t),
                });
            });
            sport.setMatchCount(sportsLiveMatchesCount);
            sport.deleteOldCategories({
                categoryIds: Object.keys(dataSource[sportId].c),
            });
        });
        s.setLiveMatchesCount(liveMatchesCount);
        if (!s.activeMatchExist && activeItems.matches.length) {
            const oldActiveMatch = getRoot(s).betting.liveMatches.matches.get(
                activeItems.matches[0]
            );
            s.pushFirstEvent(true);
            oldActiveMatch &&
                setTimeout(
                    () =>
                        getRoot(s).betting.liveMatches.deleteItem({
                            id: oldActiveMatch.id,
                        }),
                    refreshTime.liveMatches * 2
                );
        }
        branch.deleteOldSports({ sportIds: Object.keys(dataSource) });
    },
});

export default liveMatchesActions;
