import React from 'react';
import { inject, observer } from 'mobx-react';

import { LLBtn2, LLScrollBox } from '../../../../components';
import PopularTournaments from './components/popular-tournaments';
import PrematchMenu from '../../components/menu/menu';
import {PreSearchFilterPanel} from "../../components/pre-filters/pre-search-filters-panel";
import urls from '../../../../configs/urls';
import { trans } from '../../../../common/utils';
import BranchPicker from "../../components/branch-picker/branch-picker";


const PrematchLeftMenu = ({ store }) => {
    const isSearchFilterPanelOpened = store.betting.isSearchFilterPanelOpened,
          setSearchFilterPanelOpened = store.betting.setSearchFilterPanelOpened;

    const
        requestedDataLoading = store.betting.requestedDataLoading,
        isMenuDisabled = store.betting.isMenuDisabled,
        setModal = store.modal.setModal,
        activeRange = store.betting.filteredRanges.activeRange,
        filteredRanges = store.betting.filteredRanges,
        lang = store.user.language;

    const bottomButtonModalProps = {
        active: true,
        externalLink: urls.prematchStatistics + lang,
        showFullscreenButton: true,
        showCloseButton: true,
        showHeader: true,
        currentKey: 'external-link',
    };

    /***************** ~~ Render ****************/
    const extraButton1Text = trans('PRE_LEFTMENU_STATISTICS_BTN');
    const extraButton2Text = trans('PRE_LEFTMENU_LIVESCORES_BTN');
    return (
        <div className="prematch__menu-section">
            <LLScrollBox id="prematch-left-panel">
                <div
                    className={`prematch__menu${
                        isMenuDisabled || requestedDataLoading
                            ? ' prematch__menu-disabled'
                            : ''
                    }${isSearchFilterPanelOpened ? ' prematch__menu--with--panel-opened' : ''}`}
                >
                    <p
                        className="prematch__menu-filters-btn"
                        onClick={() => setSearchFilterPanelOpened(true)}
                    >
                        <span>{trans('PREMATCH_FILTERS_PANEL_HEADER')}</span>
                        <i className="prematch__menu-filters-btn-icon dx-icon-search" />
                    </p>
                    { isSearchFilterPanelOpened &&
                        <PreSearchFilterPanel {...{
                            viewSize: store.site.status.viewSize,
                            setPanelOpened: setSearchFilterPanelOpened,
                            searchResult: store.betting.branch.search.searchString,
                            filteredRanges,
                            searchFiltersFetching: activeRange?.searchFiltersFetching,
                            searchInitialFetching: store.betting.branch.search.initialFetching,
                            searchFiltersResult: activeRange?.searchFiltersResult,
                        }} />
                    }

                    <BranchPicker
                        {...{
                            language: lang,
                            viewSize: store.site.status.viewSize,
                            setPanelOpened: setSearchFilterPanelOpened
                        }}
                    />
                    <PopularTournaments />
                    <PrematchMenu />
                    <div className="prematch__menu-extrabtns-wrapper">
                        <LLBtn2
                            customClasses="prematch__menu-extra-btn with-ripple-effect"
                            onClick={() => {
                                setModal({
                                    ...bottomButtonModalProps,
                                    title: extraButton1Text,
                                });
                            }}
                        >
                            <>
                                <span>{extraButton1Text}</span>
                                <i className="llg-line-chart" />
                            </>
                        </LLBtn2>
                        <LLBtn2
                            customClasses="prematch__menu-extra-btn with-ripple-effect"
                            onClick={() =>
                                setModal({
                                    ...bottomButtonModalProps,
                                    externalLink: urls.liveScores + lang,
                                    title: extraButton2Text,
                                })
                            }
                        >
                            <>
                                <span>{extraButton2Text}</span>
                                <i className="llg-game-ball" />
                            </>
                        </LLBtn2>
                    </div>
                </div>
            </LLScrollBox>
        </div>
    );
};

export default inject('store')(observer(PrematchLeftMenu));
