import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import Slide from '@mui/material/Slide';

import {LLElementTypeSetter} from '../../../components';
import {trans} from '../../../common/utils';
import {API_PREFIX} from "../../../common/api/api-url-config";
import apiUrls from "../../../common/api/api-urls";
import cssVariables from '../../../styles/themes/theme/variables.scss';
import BattlePopupMessage from "./battle-popup-message";
import {GameImgLoader} from "../../../pages/slots-battles/components/img-loader";


let isBattlePopupMounted;


const BattlePopup = ({store}) => {
    const mainPopupIsVisible = store.site.popup.isVisible;
    const popup = store.user.battlePopup;
    const userBattleId = store.user.userBattleId;
    const getUserBattleData = store.user.getUserBattleData;
    const isSiteActive = store.site.status.isActive;
    const viewSize = store.site.status.viewSize;
    const mobileUserAgent = store.user.mobileUserAgent;
    const playingGameId = store.user.playingGameId;
    const router = store.router;
    const toggleShowInvitations = store.user.toggleShowInvitations;
    const showInvitations = store.user.showInvitations;
    const language = store.user.language;

    const [popupIsHiding, setPopupIsHiding] = useState(false);
    const [isShowInvitationsLoading, setShowInvitationsLoading] = useState(false);

    const animatingPopup = () => {
        const type = document.querySelector('.js_active-popup--type');
        if (type) {
            type.classList.add('animated');
            setTimeout(() => {
                type.classList.remove('animated');
            }, 500);
        }
    };

    useEffect(() => {
        isBattlePopupMounted = true;
        return () => {
            isBattlePopupMounted = false;
        }
    }, []);

    useEffect(() => {
        if (isBattlePopupMounted) {
            setTimeout(() => setPopupIsHiding(popup.isHiding), 100);
            if (!popup.isHiding) {
                animatingPopup();
            }
        }
    }, [popup.isHiding]);

    useEffect(() => {
        if (isSiteActive && isBattlePopupMounted) {
            getUserBattleData()
        }
    }, [isSiteActive]);

    useEffect(() => {
        if (!popup.isActive && isBattlePopupMounted) {
            setPopupIsHiding(false);
        }
    }, [popup.isActive]);

    useEffect(() => {
        if (isBattlePopupMounted) {
            if (['starting', 'completed', 'canceled'].includes(popup.whatWasChanged)) {
                popup.setHiding(false);
            }
            if (popup.isHiding) {
                animatingPopup();
            }
        }
    }, [popup.whatWasChanged]);

    useEffect(() => {
        if (isBattlePopupMounted && playingGameId) {
            popup.setWhatWasChanged('waitActualUpdate');
        }
    }, [playingGameId]);

    const elementAttributes = mobileUserAgent ? {
        href: `${API_PREFIX}${apiUrls.games.openGame}?providerId=${popup.userBattle?.ProviderID}&gameId=${popup.userBattle?.GameID}&demo=0`,
        target: "_blank",
        rel: "noopener noreferrer",
    } : {
        to: `?launch=0-${popup.userBattle?.ProviderID}-${popup.userBattle?.GameID}`,
    }

    const params = ['starting', 'started'].includes(popup.whatWasChanged) ? {...elementAttributes} : popup.roomStatus !== 'canceled' ? {to: `/slots-battles/${userBattleId}`} : {};
    const popupIn = popup.whatWasChanged !== 'waitActualUpdate' &&
        ((popup.isActive && !popup.isHiding) || popupIsHiding || (popup.whatWasChanged && !popup.whatWasChanged.includes('invitation')))
        && !(
            (router.location.pathname.includes(userBattleId) && !['starting', 'started'].includes(popup.whatWasChanged))
            || (playingGameId && !['completed', 'canceled'].includes(popup.whatWasChanged))
        );

    const bottomPosition = document.querySelector('.js-main-popup')?.clientHeight;

    return (
        <Slide direction="left"
               in={!!popupIn}
               mountOnEnter unmountOnExit>
            <div
                className={`ll-popup ll-popup--battle 
                ll-popup--${popupIsHiding ? 'battle-hidden' : 'battle-visible'} 
                ll-popup--${store.site.status.viewSize}
                ${(popup.whatWasChanged === "invitation" || popup.whatWasChanged === "invitations") && "ll-popup--battle-invitations"}
                `}
                style={(mainPopupIsVisible && bottomPosition) ? {bottom: bottomPosition + parseInt(cssVariables['css_sizeBottomIndent_slotsBattlesPopup_' + viewSize])} : {}}
            >
                <div
                    className="ll-popup__type js_active-popup--type"
                    onClick={() => (popup.whatWasChanged !== 'started' || popup.isHiding) && popup.setHiding(!popup.isHiding)}
                >
                    <i className="doruk-slot-battles"/>
                </div>
                <LLElementTypeSetter
                    className="ll-popup__inner"
                    {...popup.whatWasChanged !== "invitation" && popup.whatWasChanged !== "invitations" && {...params}}
                >
                    <div className={`ll-battle-popup__wrapper${popup?.whatWasChanged === 'invitation' ? ' invitation' : ''}`}>
                        {popup?.whatWasChanged === 'invitation' && <div className="ll-popup__image">
                            <GameImgLoader item={popup?.userBattle}/>
                        </div>}
                        <div className="ll-popup__content">
                            <div className="ll-popup__title">
                                <div className="ll-popup__header-text">
                                        <span>
                                            {popup.whatWasChanged === "invitation" ?
                                                trans("SLOT_BATTLE__POPUP_INVITATION_TITLE")
                                                : popup.whatWasChanged === "invitations" ?
                                                    trans("SLOT_BATTLE__POPUP_INVITATIONS_TITLE")
                                                    : trans('SLOT_BATTLE__POPUP_TITLE')}
                                        </span>
                                    <span className="ll-popup__header-icon ll-popup__header-icon--battle">
                                            <i className="doruk-new-hot-2"/>
                                        </span>
                                </div>
                                <div className="ll-popup__header-close"
                                     key={showInvitations}
                                     onClick={(e) => {
                                         e.preventDefault();
                                         !!(
                                             (popup.whatWasChanged && popup.whatWasChanged.includes('invitation') && !showInvitations)
                                             ||
                                             ['completed', 'canceled', 'error'].includes(popup.roomStatus)
                                         ) ?
                                             popup.close()
                                             :
                                             popup.setHiding(true);
                                     }}
                                >
                                    <i
                                        className="doruk-close ll-popup__close-button-icon"
                                    />
                                </div>
                            </div>
                            <BattlePopupMessage
                                popup={popup}
                                userBattleId={userBattleId}
                                showInvitations={showInvitations}
                                language={language}
                                router={router}
                                isShowInvitationsLoading={isShowInvitationsLoading}
                                setShowInvitationsLoading={setShowInvitationsLoading}
                                toggleShowInvitations={toggleShowInvitations}
                            />
                        </div>
                    </div>
                </LLElementTypeSetter>
            </div>
        </Slide>
    );
};

export default inject('store')(observer(BattlePopup));
