/* eslint-disable react/display-name */
import React from 'react';
import {
    AboutUsPage,
    HomePage,
    SportPage,
    SlotsCasinos,
    SlotsBattles,
    PromoPage,
    ForgotPassword,
    ResetPassword,
    ChangePassword,
    MyAccountPage,
    BetOnGames,
    Twain,
    LiveGames,
    Lottery,
    TvBet,
    ErrorPage,
    ContactUsPage,
    MessagesPage,
    MailVerifyPage,
    Lobby,
} from './../../pages';

const retrobetPagesConfig = {
    homePage: {
        name: 'Homepage',
        Component: (props) => <HomePage {...props} />,
        url: '/',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Spor Bahisleri | Canlı Bahisler | Slotlar | Canlı Casino | RetroBet',
            description:
                'RetroBet Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın',
            keywords:
                'bahis, canlı bahis, bahis sitesi, canlı bahis siteleri, bahis siteleri, casino, poker, tombala, at yarışı',
        },
    },
    livePage: {
        name: 'Live',
        Component: (props) => <SportPage {...props} />,
        url: '/sport/live/:sportId?/:categoryId?/:tournamentId?/:matchId?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Canlı Bahis | RetroBet',
            description:
                'Canlı bahis alanında en çok canlı bahis çeşiti, en yüksek canlı bahis oranlar ve en çok canlı bahis karşılaşma ile en iyi bahis sitesi olan RetroBet\'ya gelin, kazanın!',
            keywords:
                'canlı bahis, canlı bahis sitesi, en iyi canlı bahis, en güvenilir canlı bahis',
        },
    },
    sportPage: {
        name: 'Prematch',
        Component: (props) => <SportPage {...props} />,
        url: '/sport/:branchId?/:sportId?/:categoryId?/:tournamentId?/:matchId?/:matchQueryParam?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Spor Bahisleri | RetroBet',
            description:
                'Futbol, basketbol, tenis, voleybol ve hentbol gibi tüm spor branları için oynana bahis çeşitleri ve diğer tüm spor bahisleri, yüksek oranlar ile RetroBet’te!',
            keywords:
                'spor bahisleri, spor bahis, futbol bahisleri, basketbol bahisleri',
        },
    },
    casinosPage: {
        name: 'Casino',
        Component: (props) => <SlotsCasinos {...props} gamesType="casino" />,
        url: '/casino/:viewType?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Canlı Casino | RetroBet',
            description:
                'Rulet, blackjack, baccarat, casino hold’em gibi popüler casino oyunlarını canlı yayın aktarımları ile RetroBet’in canlı casino salonunda oynayın!',
            keywords:
                'canlı casino, canlı rulet, canlı blackjeack, canlı baccarat, canlı türk ruleti, canlı holdem',
        },
    },
    slotsPage: {
        name: 'Slots',
        Component: (props) => <SlotsCasinos {...props} gamesType="slots" />,
        url: '/slots/:viewType?/:jackpotId?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Slot Oyunları | RetroBet',
            description:
                'Slot oyunları alanında en çok slot oyun çeşiti ile en iyi casino sitesi olan RetroBet\'ya gelin, canlı casino\'daki slot oyunlarında kazanın!',
            keywords: 'slot, slot oyna, slot oyunları, canlı slot',
        },
    },
    slotsBattlePage: {
        name: 'SlotsBattle',
        Component: (props) => <SlotsBattles {...props} />,
        url: '/slots-battles/:gameId?',
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Slot Battle',
            description:
                "Slot Battle Türkiye'de ilk",
            keywords: '',
        },
    },
    forgotPassword: {
        name: 'Forgot Password',
        Component: (props) => <ForgotPassword {...props} />,
        url: '/forgot-password',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet'],
        meta: {
            title: 'Şifremi unuttum',
            description: '',
            keywords: '',
        },
        logoutRequired: true,
    },
    resetPassword: {
        name: 'Reset Password',
        Component: (props) => <ResetPassword {...props} />,
        url: '/passwordreset',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Şifre yenileme',
            description: '',
            keywords: '',
        },
        logoutRequired: true,
    },
    changePassword: {
        name: 'Change password',
        Component: (props) => <ChangePassword {...props} />,
        url: '/change-password',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet'],
        meta: {
            title: 'Şifre Değiştir',
            description: '',
            keywords: '',
        },
        loginRequired: true,
    },
    promoPage: {
        name: 'Promopage',
        Component: (props) => <PromoPage {...props} />,
        url: '/promo',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Bonuslar | RetroBet',
            description:
                'En yüksek oranlı bonuslar ve en düşük çevrim şartına sahip bonus veren bahis sitesi olan RetroBet ile kazanın!',
            keywords: 'bonus, bahis bonusları, casno bonusları, bonus veren siteler',
        },
    },
    aboutUsPage: {
        name: 'About Us',
        Component: (props) => <AboutUsPage {...props} />,
        url: '/aboutus',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Hakkımızda',
            description: '',
            keywords: '',
        },
    },
    myAccountPage: {
        name: 'My account',
        Component: (props) => <MyAccountPage {...props} />,
        url: '/account',
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Hesabım',
            description: '',
            keywords: '',
        },
        loginRequired: true,
        allowedForBlocked: true,
    },
    betOnGames: {
        name: 'Bet Games',
        Component: (props) => <BetOnGames {...props} />,
        url: '/bet-games',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'BetOnGames Canlı Casino Oyunları | RetroBet',
            description:
                "BetOnGames oyunları ile canlı casino ve casino alanında en çok casino oyun çeşiti ile en iyi casino sitesi olan RetroBet'ya gelin, canlı casino'daki slot oyunlarında kazanın!",
            keywords: 'slot, slot oyna, slot oyunları, canlı slot',
        },
    },
    twain: {
        name: 'Twain',
        Component: (props) => <Twain {...props} />,
        url: '/twain',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Twain',
            description:
                "Twain",
            keywords: 'Twain',
        },
    },
    liveGames: {
        name: 'Live games',
        Component: (props) => <LiveGames {...props} />,
        url: '/live-games',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Tombala | RetroBet',
            description:
                "Canlı tombala alanında en çok tombala oyun çeşiti ile en iyi tombala sitesi olan RetroBet'ya gelin, tombala oyunlarında kazanın!",
            keywords: 'tombala, canlı tombala, tombala oyunları, tombala oyna',
        },
    },
    lottery: {
        name: 'Lottery',
        Component: (props) => <Lottery {...props} />,
        url: '/lottery',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Loto | RetroBet',
            description: 'Loto oyna',
            keywords: 'loto, loto oyna',
        },
    },
    tvbet: {
        name: 'TVBET',
        Component: (props) => <TvBet {...props} />,
        url: '/tvbet',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'TVBET',
            description: '',
            keywords: '',
        },
    },
    helpPage: {
        name: 'Contact Us',
        Component: (props) => <ContactUsPage {...props} />,
        url: '/contact-us',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Bize Ulaşın',
            description: '',
            keywords: '',
        },
    },
    mailVerify: {
        name: 'Mail Verify',
        Component: (props) => <MailVerifyPage {...props} />,
        url: '/mailverify',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'E-Posta doğrulama',
            description: ' ',
            keywords: ' ',
        },
    },
    messages: {
        name: 'Messages',
        Component: (props) => <MessagesPage {...props} />,
        url: '/user/messages',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet'],
        meta: {
            title: 'Mesajlar',
            description: '',
            keywords: '',
        },
        loginRequired: true,
    },
    lobbyPage: {
        name: 'Lobby',
        Component: (props) => <Lobby {...props} />,
        url: '/lobby',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Lobby',
            description: '',
            keywords: '',
        },
        loginRequired: true,
    },
    error404Page: {
        name: 'Error',
        Component: (props) => <ErrorPage {...props} />,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: '404',
            description: '',
            keywords: '',
        },
    },
};

export default retrobetPagesConfig;
