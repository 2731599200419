import React from 'react';

import { withCommon } from '../../../../components';
import urls from '../../../../configs/urls';
import { DrawerCloseButton } from '../../components';

const MessagesPage = withCommon({
    store: ({ store }) => ({
        user: store.user,
        viewSize: store.site.status.viewSize,
    }),
    isTrans: true,
})(({ user, viewSize, trans }) => (
    <div className="messages">
        {viewSize === 'desktop' && (
            <p className="my-account__title">
                <span className="my-account__title-text">{trans('messages')}</span>
                <DrawerCloseButton />
            </p>
        )}
        <div className="my-account__content">
            <iframe
                frameBorder="0"
                title="user-messages"
                src={`${urls.messages}?sessionid=${user.sId}&customerid=${user.customerId}&lang=${user.language}`}
                allowFullScreen
                height="100%"
                width="100%"
                className="message-iframe"
            />
        </div>
    </div>
));

export default MessagesPage;
