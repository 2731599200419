import React, {useEffect} from 'react';
import cx from 'classnames';

import SearchPanel from "../search-panel";
import SearchResult from "./search-result";
import FiltersPanel from "./filters";
import FilterResult from "./filter-result";
import {LLPreloader} from "../../../../components";
import trans from '../../../../common/utils/trans';


export const PreSearchFilterPanel = (
    {
        setPanelOpened,
        viewSize,
        searchResult,
        filteredRanges,
        searchFiltersFetching,
        searchInitialFetching,
        searchFiltersResult,
        couponsList,
    }
) => {
    useEffect(() => () => {
        filteredRanges.activeRange?.clearAll();
        setPanelOpened(false);
    }, []);

    return (
        <div className={cx(
            'pre-filters-panel',
            `pre-filters-panel--${viewSize}`,
            couponsList?.length ?
                couponsList.length > 1 ?
                    'pre-filters-panel--with-coupons'
                    : 'pre-filters-panel--with-coupon'
                : ''
        )}>
            <div className="filters-panel__header">
                <p>{trans("PREMATCH_FILTERS_PANEL_HEADER")}</p>
                <span className="filters-panel__close-icon-wrp"
                      onClick={() => setPanelOpened(false)}
                >
                    <i className="filters-panel__close-icon doruk-close"/>
                </span>
            </div>
            {(searchFiltersFetching || searchInitialFetching) &&
                <div className="pre-filters-panel__loader">
                    <LLPreloader height={20} width={20}/>
                </div>
            }
            <div className="pre-filters-panel__container">
                <div className="pre-filters-panel__container-inner">
                    {!searchFiltersResult ?
                        <>
                            <div className="filters-panel__item">
                                <p className="filters-panel__item-label">{trans('Search')}</p>
                                <SearchPanel focusSearch={viewSize === 'desktop'}/>
                            </div>
                            <SearchResult/>
                            {!searchResult ?
                                <div className="filters-panel__item">
                                    <FiltersPanel/>
                                </div>
                                :
                                null
                            }
                        </>
                        :
                        <FilterResult/>
                    }
                </div>
            </div>
        </div>
    );
};