import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

import {
    LLScrollBox,
    BetradarWidgets,
    LLPreloader,
} from '../../../../components';
import { trans } from '../../../../common/utils';
import { LiveScoreboardWithBreadcrumbs, MarketGroupsSlider } from '../../components';
import MatchDetailsTitle from "../../mobile/components/match-details-title";


const LLMatchDetails = ({store}) => {
    const prematchRouterMatchInstance = store.router.prematchRouterMatchInstance,
        matchDetailError = store.betting.bettingErrors.detail,
        clearError = store.betting.clearError,
        viewSize = store.site.status.viewSize,
        language = store.user.language,
        userCanCashout = store.user.canCashout,
        activeItems = store.betting.activeItems;

    const marketGroupsArr = prematchRouterMatchInstance ? prematchRouterMatchInstance.marketGroupsList() : [];

    const [activeMarketGroup, setActiveMarketGroup] = useState(activeItems.marketGroups.length ? parseInt(activeItems.marketGroups[0]) : -1);
    let bananaDefender = true;

    useEffect(
        () => () => {
            bananaDefender = false;
            clearError({type: 'detail'});
        },
        []
    );

    useEffect(() => {
        if (bananaDefender) {
            setActiveMarketGroup(activeItems.marketGroups.length ? parseInt(activeItems.marketGroups[0]) : -1);
        }
    }, [prematchRouterMatchInstance?.id]);

    useEffect(() => {
        if (marketGroupsArr?.length) {
            const activeGroup = marketGroupsArr.find((group) =>
                activeItems.isActive({
                    id: group.id,
                    type: 'marketGroups',
                })
            );
            if ((!activeGroup || activeGroup.id === marketGroupsArr[0].id)) {
                bananaDefender &&
                activeItems.setActiveItem(
                    [marketGroupsArr[0].id.toString()],
                    'marketGroups'
                );
            }
        }
    }, [prematchRouterMatchInstance && prematchRouterMatchInstance.marketGroupsList().length]);

    return (
        <LLScrollBox id="match-detail">
            <LLPreloader
                fullPage
                visible={prematchRouterMatchInstance?.initialFetching}
                height={50}
                width={50}
                timeout={300}
                className="ll-preloader__prematch-detail"
            />
            { prematchRouterMatchInstance && !prematchRouterMatchInstance?.initialFetching && (
                <div
                    className={`match-details--${viewSize}`}
                    data-match-id={prematchRouterMatchInstance?.id}
                >
                    { prematchRouterMatchInstance && (
                        <>
                            { viewSize === 'mobile' && (
                                <MatchDetailsTitle match={prematchRouterMatchInstance}/>
                            )}

                            <LiveScoreboardWithBreadcrumbs
                                matchInstance={prematchRouterMatchInstance}
                                viewSize={viewSize}
                                language={language}
                            />

                            {matchDetailError ? (
                                <div className="match-details__error">
                                    {trans(matchDetailError)}
                                </div>
                            ) : (
                                <MarketGroupsSlider
                                    activeMarketGroup={activeMarketGroup}
                                    setActiveMarketGroup={setActiveMarketGroup}
                                    setActiveItem={activeItems.setActiveItem}
                                    matchInstance={prematchRouterMatchInstance}
                                    userCanCashout={userCanCashout}
                                    isMarketGroupsNeeded={prematchRouterMatchInstance.isMarketGroupsNeeded}
                                    type={'pre'}
                                />
                            )}

                            <BetradarWidgets
                                matchId={prematchRouterMatchInstance.id}
                                type="match.preview"
                            />
                        </>
                    )}
                </div>
            )}
        </LLScrollBox>
    );
};

export default inject('store')(observer(LLMatchDetails));
