import { values } from 'mobx';

/*
##     ##    ###    ########  ##    ## ######## ########     ######   ########   #######  ##     ## ########
###   ###   ## ##   ##     ## ##   ##  ##          ##       ##    ##  ##     ## ##     ## ##     ## ##     ##
#### ####  ##   ##  ##     ## ##  ##   ##          ##       ##        ##     ## ##     ## ##     ## ##     ##
## ### ## ##     ## ########  #####    ######      ##       ##   #### ########  ##     ## ##     ## ########
##     ## ######### ##   ##   ##  ##   ##          ##       ##    ##  ##   ##   ##     ## ##     ## ##
##     ## ##     ## ##    ##  ##   ##  ##          ##       ##    ##  ##    ##  ##     ## ##     ## ##
##     ## ##     ## ##     ## ##    ## ########    ##        ######   ##     ##  #######   #######  ##

 ######   #######  ##     ## ##     ##    ###    ##    ## ########   ######
##    ## ##     ## ###   ### ###   ###   ## ##   ###   ## ##     ## ##    ##
##       ##     ## #### #### #### ####  ##   ##  ####  ## ##     ## ##
##       ##     ## ## ### ## ## ### ## ##     ## ## ## ## ##     ##  ######
##       ##     ## ##     ## ##     ## ######### ##  #### ##     ##       ##
##    ## ##     ## ##     ## ##     ## ##     ## ##   ### ##     ## ##    ##
 ######   #######  ##     ## ##     ## ##     ## ##    ## ########   ######
*/

const marketGroupCommands = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                Update and put markers                                ##
    // ##                                                                                      ##
    // ##========================================================================================

    putUpdateMarkets({ dataSource = [], isFeatured = false }) {
        dataSource.map(({ i: identifierNumber, o, dc, b, n, coa }) => {
            if (s.markets.has(identifierNumber)) {
                /**** ~~ Update old ones from existing data ****/
                s.markets.get(identifierNumber).update({ o, dc, b, coa }, isFeatured);
            } else {
                /**** ~~ Fill new ones from existing data ****/
                s.markets.put({
                    id: identifierNumber,
                    columnCount: dc > 3 ? 1 : dc,
                    order: o,
                    name: n,
                    baseName: n,
                    canCashout: !!coa,
                });
                /**** ~~ Fill bets of market ****/
                b && s.markets.get(identifierNumber).setBets(b);
                s.markets.get(identifierNumber).marketName();
            }
        });
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                 Market groups updater                                ##
    // ##                                                                                      ##
    // ##========================================================================================

    update({ o: order, m: markets, n: name }, isFeatured) {
        if (order !== s.order && order !== 1) {
            s.order = order;
        }
        name && (s.name = name);
        markets && s.putUpdateMarkets({ dataSource: markets, isFeatured });
    },

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                               Old market groups deleter                              ##
    // ##                                                                                      ##
    // ##========================================================================================

    deleteOldMarkets({ incomingMarkets }) {
        values(s.markets)
            .filter(({ id }) => !incomingMarkets.some(({ i }) => i === id))
            .map((oldMarket) => {
                const market = s.markets.get(oldMarket.id);
                if (market) {
                    market.removeItems();
                }
            });
    },
});

export default marketGroupCommands;
