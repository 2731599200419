import {types as t, getRoot, getParent} from 'mobx-state-tree';
import {values} from 'mobx';
import orderBy from 'lodash/orderBy';

import BettingItem from './betting';
import {generateCombinations} from '../../../../../common/utils';
import {sortBy} from "lodash";

/***************** ~~ Market item model ***************
 * @file - Represents Market Item model
 * @author S.TARNAVSKI
 */

const MarketItem = t
    .model('MarketItem', {
        id: t.identifierNumber /* i */,
        order: t.integer /* o */,
        columnCount: t.maybeNull(t.integer) /* dc */,
        bets: t.map(BettingItem) /* b */,
        name: t.maybeNull(t.string),
        baseName: t.maybeNull(t.string),
        canCashout: t.boolean,
    })
    /***************** ~~ Actions ****************/
    .actions((s) => ({
        removeItems() {
            values(s.bets).map((bet) => s.bets.delete(bet.id));
            getParent(s, 2).deleteItem({id: s.id});
        },

        /**** ~~ Fills or update bets behavior ****/
        setBets(b) {
            b.map(({i: identifierNumber, c, sf, v, ov, on, o, bs, bl, a = 1, coa}) => {
                if (!s.bets.has(identifierNumber)) {
                    /**** ~~ Fills bets if it not exist yet ****/
                    s.bets.put({
                        id: identifierNumber,
                        outcomeTypeId: c.toString(),
                        specifier: sf,
                        outcomeValue: v,
                        originalOutcomeValue: ov,
                        baseName: `${on}`,
                        order: o,
                        available: !!a,
                        betStatus: !!bs,
                        blink: bl,
                    });
                }
                //generate name for each bets
                s.bets.get(identifierNumber).outcomeName();
            });
        },

        /**** ~~ Update behavior ****/
        update({o, dc, b: incomingBets, coa}, isFeatured = false) {
            s.order = o;
            s.canCashout = !!coa;
            s.columnCount = dc > 3 ? 1 : dc;

            if (incomingBets) {
                /**** ~~ Handle new bets ****/
                let newBets = [];

                /**** ~~ Collect new and update existing bets ****/
                incomingBets.map((newBet) => {
                    s.bets.has(newBet.i)
                        ? /**** ~~ Update existing data from incoming data ****/
                        s.bets.get(newBet.i).update(newBet)
                        : /**** ~~ Collect new bets data ****/
                        (newBets = [...newBets, newBet]);
                });

                /**** ~~ Sets bets from incoming data ****/
                newBets.length > 0 && s.setBets(newBets);

                if (!isFeatured) {
                    /**** ~~ Delete old bets ****/
                    s.betsList
                        .filter(
                            (oldBet) =>
                                !incomingBets.some(
                                    (incomingBet) => incomingBet.i === oldBet.id
                                )
                        )
                        .map((oldBet) => {
                            /**** ~~ Delete old bet ****/
                            s.bets.get(oldBet.id).delete();
                        });
                }
            } else {
                s.removeItems();
            }
        },

        marketName() {
            s.name = getRoot(s).getReplacer({
                strToReplace: s.name,
                dataSource: {
                    activeMatch: getParent(s, 4),
                    specifier: s.firstBetSpecifier,
                },
            });
        },
    }))
    /***************** ~~ Views ****************/
    .views((s) => ({
        sortedBets(betsArray) {
            const sortStringArray = [];
            if (betsArray.length) {
                Object.keys(betsArray[0].specifiers).map((specifierKey) => {
                    sortStringArray.push(`specifiers.${specifierKey}.specifierOrder`);
                });
            }
            let sortedArray = orderBy(
                betsArray,
                [...sortStringArray, 'order', 'outcomeValue', 'name'],
                'asc'
            );

            //hide bets with 0 value (blocked) for markets with odds more than columnCount
            if (
                sortedArray.length > s.columnCount &&
                getRoot(s).betting.branch.id !== 'Live'
            ) {
                sortedArray = sortedArray.filter((bet) => bet.outcomeValue);
            }

            return sortedArray;
        },

        rowsList(betsArray) {
            const bets = betsArray;
            const rows = [];
            let betsInRow = [];
            let betsCount = 0;
            let skipRow = true;
            const sportId = getParent(s, 4).sportId;

            const fillRows = (bets, columnCount) => {
                let i;
                const betProcess = (needAtLeast1Row = false) => {
                    i = 0;
                    do {
                        betsCount++;
                        //if need new new row
                        if (betsCount > columnCount) {
                            betsCount = 1;
                            betsInRow = [];
                            skipRow = true;
                        }
                        //check new row
                        if (betsCount === 1) {
                            for (let j = i; j < i + columnCount; j++) {
                                //not skip row if at least 1 good                       
                                //if (bets[j]?.outcomeValue > 1 && bets[j]?.available) {
                                if (bets[j]?.outcomeValue > 1) {
                                    skipRow = false;
                                }
                                //crunches for Soccer (sportId = 1)
                                if (sportId === '1') {
                                    //crutch for remove rows for Under/Over markets when at least one bet in row have value <= 1
                                    if ([18, 19, 20, 68, 69, 70].includes(s.id) && bets[j]?.outcomeValue <= 1 && !needAtLeast1Row) {
                                        skipRow = true;
                                        break;
                                    }
                                }
                            }
                            //fill new row if check is ok
                            if (!skipRow) {
                                for (let k = i; k < i + columnCount; k++) {
                                    betsInRow.push(bets[k]);
                                }
                                rows.push({
                                    columnCount: columnCount,
                                    bets: betsInRow
                                })
                                if (needAtLeast1Row) {
                                    i = bets.length
                                }
                            }
                        }
                        i++;
                    } while (i < bets.length);
                }

                betProcess(false);

                //getting at least 1 line for those markets.
                if ([18, 19, 20, 68, 69, 70].includes(s.id) & rows.length === 0) {
                    betProcess(true);
                }
            };

            //collect bets into rows (to use columnCount)
            //Crunch for score markets
            if ([199, 81, 98, 41, 45, 80, 401, 402, 430, 431, 374].includes(s.id)) {
                if (getRoot(s).user.isOldMarketsView) {
                    const scoreMarketsBetsArrays = s.scoreMarketsBetsList;
                    scoreMarketsBetsArrays[0].length && fillRows(scoreMarketsBetsArrays[0], 3);
                    scoreMarketsBetsArrays[1].length && fillRows(scoreMarketsBetsArrays[1], 2);
                } else {
                    fillRows(s.scoreMarketsBetsListForSlider, s.columnCount);
                }
            } else {
                //normal markets
                fillRows(bets, s.columnCount);
            }

            return rows;
        },

        get betsLength() {
            return values(s.bets).length;
        },
        get firstBetSpecifier() {
            const bet = values(s.bets)[values(s.bets).length - 1];
            return bet?.specifier || '';
        },
        get betsList() {
            return s.sortedBets(values(s.bets));
        },
        get betRowsList() {
            return s.rowsList(s.betsList);
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                           Array of specifier based markets                           ##
        // ##                                                                                      ##
        // ##========================================================================================

        get specifierBasedMarkets() {
            const betsArray = values(s.bets);
            const specifiers = betsArray[0]?.specifiers;
            const specifierBasedMarketsArray = [];
            const uniques = {};

            if (specifiers && Object.keys(specifiers).length) {
                Object.keys(specifiers).map((specifier) => {
                    const commonMacroRegex = new RegExp(`{.{0,1}${specifier}}`, 'gi');
                    if (commonMacroRegex.test(s.baseName)) {
                        const uniQueSpecifierValues = [];
                        betsArray.map((bet) => {
                            const val = bet.specifiers[specifier].value;
                            if (!uniQueSpecifierValues.includes(val)) {
                                uniQueSpecifierValues.push(val);
                            }
                        });
                        uniques[specifier] = uniQueSpecifierValues;
                    }
                });

                generateCombinations(uniques).map((uniq) => {
                    let bets = betsArray;
                    Object.keys(uniq).map((macro) => {
                        bets = bets.filter(
                            (bet) => bet.specifiers[macro].value === uniq[macro]
                        );
                    });

                    if (bets.find(bet => bet.outcomeValue !== 0)) {
                        const name = getRoot(s).getReplacer({
                            strToReplace: s.baseName,
                            dataSource: {
                                activeMatch: getParent(s, 4),
                                specifier: bets[0]?.specifier || '',
                            },
                        });

                        !specifierBasedMarketsArray.find(
                            (sMarket) => sMarket.name === name
                        ) &&
                        specifierBasedMarketsArray.push({
                            ...s,
                            name: name,
                            betRowsList: s.rowsList(s.sortedBets(bets)),
                            betsList: s.sortedBets(bets),
                        });
                    }
                });
            }
            return orderBy(specifierBasedMarketsArray, ['name'], 'asc');
        },

        /*** For markets with ID's in 199, 81, 98, 41, 45, 80, 401, 402, 430, 431, 374 - special rules for bets visualization */
        get scoreMarketsBetsList() {
            if ([199, 81, 98, 41, 45, 80, 401, 402, 430, 431, 374].includes(s.id)) {
                const firstPartOfBets = [],
                    secondPartOfBets = [],
                    bets = values(s.bets);

                /* [d1:d2] [m:m] [d2:d1]
                    to form rows of bets like [1:0] [0:0] [0:1] or [2:1] [2:2] [1:2]
                */
                for (let m = 0; m < 10; m++) {
                    let d1, d2;
                    switch (m) {
                        case 0:
                        case 1:
                            d1 = m + 1;
                            d2 = 0;
                            break;
                        case 2:
                            d1 = m;
                            d2 = 1;
                            break;
                        case 4:
                            d1 = m - 1;
                            d2 = 1;
                            break;
                        case 5:
                            d1 = m - 2;
                            d2 = 2;
                            break;
                        default:
                            d1 = m;
                            d2 = 0;
                            break;
                    }
                    let res1 = bets.find(({baseName}) => baseName === `${d1}:${d2}`);
                    let res2 = bets.find(({baseName}) => baseName === `${m}:${m}`);
                    let res3 = bets.find(({baseName}) => baseName === `${d2}:${d1}`);
                    if (res1 && res2 && res3) {
                        firstPartOfBets.push(res1);
                        firstPartOfBets.push(res2);
                        firstPartOfBets.push(res3);
                        bets.splice(bets.indexOf(res1), 1);
                        bets.splice(bets.indexOf(res2), 1);
                        bets.splice(bets.indexOf(res3), 1);
                    }
                }
                /* Sort rest of bets */
                for (let q = 0; q < 10; q++) {
                    for (let m = 0; m < q; m++) {
                        let res1 = bets.find(({baseName}) => baseName === `${q}:${m}`);
                        let res2 = bets.find(({baseName}) => baseName === `${m}:${q}`);
                        if (res1 && res2) {
                            secondPartOfBets.push(res1);
                            secondPartOfBets.push(res2);
                            bets.splice(bets.indexOf(res1), 1);
                            bets.splice(bets.indexOf(res2), 1);
                        }
                    }
                }
                return [firstPartOfBets, [...secondPartOfBets, ...bets]];
            } else return [[], []];
        },
        get scoreMarketsBetsListForSlider() {
            return sortBy(values(s.bets), [
                (item) => {
                    return +item?.name?.split(':')[0];
                },
                (item) => {
                    return +item?.name?.split(':')[1];
                },
            ])
        },
    }));

export default MarketItem;
