import React from 'react';
import Swipe from 'react-easy-swipe';
import {throttle} from "lodash";

import {withCommon} from '../../components';


const BetDrawerSwiper = withCommon({
    store: ({store}) => ({
        viewSize: store.site.status.viewSize,
        open: store.drawer.open,
        statusFilter: store.user.userBets.statusFilter,
        setStatusFilter: store.user.userBets.setStatusFilter,
        isLogged: store.user.isLogged,
    }),
    defaultName: 'BetDrawerSwiper',
    isObserver: true,
})(({
        viewSize,
        open,
        setStatusFilter,
        statusFilter,
        isLogged,
    }) => {

    const handleSwipeMove = throttle((offset, e) => {
        if (isLogged && offset.x < 0 && Math.abs(offset.x) > 100) {
            if (!statusFilter.length) {
                setStatusFilter('activeBets')
            }
            open({side: 'right', currentKey: 'my-bets-drawer'});
        }
    }, 500);

    /***************** ~~ Render ****************/
    return (<>
        <Swipe onSwipeMove={handleSwipeMove}>
            {viewSize !== 'desktop' ? <div className="bet-drawer-swiper"/> : null}
        </Swipe>
    </>);
});

export default BetDrawerSwiper;