import {types, getRoot} from 'mobx-state-tree';

import {PaymentItem} from '../user/models/payments';
import {trans, resizeHandler} from '../../../common/utils';


const ModalGame = types.model('ModalGame', {
    gameId: types.identifier,
    mode: types.maybeNull(types.number),
    gameType: types.maybeNull(types.string),
    gameUrl: types.maybeNull(types.string),
    gameHeight: types.maybeNull(types.number),
    gameWidth: types.maybeNull(types.number),
    onCloseGoTo: types.maybeNull(types.string),
});

const Modal = types
    .model('Modal', {
        isActive: types.boolean,
        isError: types.boolean,
        currentKey: types.maybeNull(types.string),
        customClasses: types.maybeNull(types.string),
        externalLink: types.maybeNull(types.string),
        showCloseButton: types.boolean,
        showFullscreenButton: types.boolean,
        closeOnOuterClick: types.boolean,
        maskBackground: types.boolean,
        showHeader: types.boolean,
        games: types.map(ModalGame), //в SPIN'е будут открываться несколько игр в одном окне
        activeGame: types.maybeNull(types.reference(ModalGame)),
        onCloseGoTo: types.maybeNull(types.string),
        goBackUrl: types.maybeNull(types.string),
        content: types.maybeNull(
            types.union(
                {eager: false},
                types.reference(PaymentItem),
                types.map(ModalGame)
            )
        ),
        isVaix: false,
        betInfo: types.frozen({}),
    })
    .volatile((s) => ({
        customContent: {},
        title: null,
    }))
    .actions((self) => ({
        setModal({
                     active = false,
                     isError = false,
                     goBackUrl = null,
                     currentKey = '',
                     title = null,
                     customClasses = '',
                     externalLink = '',
                     showCloseButton = false,
                     showFullscreenButton = false,
                     closeOnOuterClick = true,
                     maskBackground = true,
                     showHeader = true,
                     onCloseGoTo = '',
                     customContent = null,
                     content = null,
                     betInfo = {}, // for custom bets
                     isVaix = false, // for VAIX bets
                     is100vh = false, //needed to recalculate height of modal for IOS
                 }) {
            if (self.isActive) {
                self.isActive = false;
                setTimeout(() => {
                    self.setModal({
                        isActive: true,
                        isError: isError,
                        goBackUrl: goBackUrl,
                        currentKey: currentKey,
                        title: title,
                        customClasses: customClasses,
                        externalLink: externalLink,
                        showCloseButton: showCloseButton,
                        showFullscreenButton: showFullscreenButton,
                        closeOnOuterClick: closeOnOuterClick,
                        maskBackground: maskBackground,
                        showHeader: showHeader,
                        onCloseGoTo: onCloseGoTo,
                        customContent: customContent,
                        content: content,
                        betInfo: betInfo,
                        isVaix: isVaix,
                        is100vh: is100vh,
                    });
                }, 200);
            } else {
                self.isActive = true;
                self.isError = isError;
                self.goBackUrl = goBackUrl;
                self.currentKey = currentKey;
                self.title = title ? title : isError ? trans('Error') : '';
                self.customClasses = customClasses;
                self.externalLink = externalLink;
                self.showCloseButton = showCloseButton;
                self.showFullscreenButton = showFullscreenButton;
                self.closeOnOuterClick = closeOnOuterClick;
                self.maskBackground = maskBackground;
                self.showHeader = isError ? true : showHeader;
                self.onCloseGoTo = onCloseGoTo;
                self.customContent = customContent;
                self.content = content;
                if (currentKey === 'login-form') {
                    self.title = trans('Member Login');
                    self.maskBackground = true;
                    self.showHeader = true;
                }
                self.betInfo = betInfo;
                self.isVaix = isVaix;
                if (is100vh) {
                    resizeHandler();
                }
            }
            if (!active) self.activeGameId = '';
        },
        close(closeCallback = null) {
            self.isActive = false;
            if (typeof closeCallback === 'function') {
                closeCallback();
            }
            getRoot(self).site.status.setBackdropShown(false);
            self.customContent = null;
            self.content = null;
            self.betInfo = {};
            if (self.currentKey === 'success-bet-modal') {
                getRoot(self).user.coupons.clearCouponsInfo();
                getRoot(self).user.coupons.getCouponsList();

                if (getRoot(self).user.coupons.isCustom) {
                    const match = getRoot(self).router.prematchRouterMatchInstance;
                    if (match) {
                        match.setLastCustomMarketGroupsFetchTime(null);
                        match.setCustomBetsFetching(true);
                        match.initializeAllMarketsInstruction().then(() => {
                            match.setCustomBetsFetching(false);
                        });
                    }
                }
            }
            const prevKey = self.currentKey;
            self.currentKey = '';
            if (prevKey === 'launch-game') {
                self.activeGame = null;
                self.games.clear();
                getRoot(self).router.clearLaunchFromURL();
            }
        },
        setGame(game) {
            self.activeGame && self.removeActiveGame();
            game.mode = parseInt(game.mode);
            game.gameHeight = parseInt(game.gameHeight);
            game.gameWidth = parseInt(game.gameWidth);
            self.games.set(game.gameId, game);
            self.activeGame = game.gameId;
        },
        removeActiveGame() {
            self.activeGame = null;
        },
        setActiveGame(gameId) {
            self.activeGame = gameId;
        },
    }));

export default Modal;
