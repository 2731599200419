const prefix = '/api';

const apiUrls = {
    global: {
        config: prefix + '/global/config',
        language: prefix + '/global/language',
        miniBanners: prefix + '/global/mini-banner',
        refreshData: prefix + '/global/refresh-data',
    },
    games: {
        list: prefix + '/games',
        sortedFilters: prefix + '/games/sortedFilters',
        openGame: prefix + '/games/play',
        slotBanner: prefix + '/games/slot-banner',
        casinoBanner: prefix + '/games/casino-banner',
        random: prefix + '/games/random',
        favoriteGame: prefix + '/games/favourite',
        jackpotDetails: prefix + '/games/jackpot-details',
        lobbies: prefix + '/games/lobby',

        roomsList: prefix + '/battle/rooms',
        myRoomsList: prefix + '/battle/my-rooms',
        battleGamesList: prefix + '/battle/games',
        createRoom: prefix + '/battle/create',
        joinRoom: prefix + '/battle/join',
        leaveRoom: prefix + '/battle/leave',
        roomInfo: prefix + '/battle/info',
        brands: prefix + '/battle/brands',
        battlesBanner: prefix + '/battle/banner',
    },
    virtual: {
        play: prefix + '/virtual/play',
    },
    user: {
        signup: prefix + '/user/signup',
        session: prefix + '/global/session',
        login: prefix + '/user/login',
        logout: prefix + '/user/logout',
        authGoogle: prefix + '/user/auth-google',
        authSms: prefix + '/user/auth-sms',
        resendSms: prefix + '/user/resend-sms',
        forgetPassword: prefix + '/user/forgot-password',
        resetPassword: prefix + '/user/reset-password',
        changePassword: prefix + '/user/change-password',
        // Cabinet
        deposits: prefix + '/account/deposits',
        withdraws: prefix + '/account/withdraws',
        withdrawStatus: prefix + '/account/requests',
        accountReport: prefix + '/account/report',
        accountHistory: prefix + '/account/history',

        cashoutCheck: prefix + '/account/active-coupon',
        activeBets: prefix + '/account/active-coupons',
        getCashout: prefix + '/coupon/cashout',

        betHistory: prefix + '/account/coupons',
        betHistoryDetails: prefix + '/account/coupon-detail',
        betDelete: prefix + '/account/coupon-delete',
        casinoReport: prefix + '/account/casino',
        limits: prefix + '/account/limits',
        myInfo: prefix + '/account/info',
        googleQrCode: prefix + '/user/google-qr-code',
        googleAuth: prefix + '/user/google-auth-set',
        verifyMail: prefix + '/user/verify-mail',
        invitations: prefix + '/user/sb-invitation',
    },
    home: {
        homeConfig: prefix + '/global/home',
    },
    betting: {
        sports: prefix + '/prematch/sports',
        rangedByDateSports: prefix + '/prematch/pre-sport',
        getWidget: prefix + '/prematch/pre-featured',
        widgetLogs: prefix + '/prematch/pre-featured',
        sportMatches: prefix + '/prematch/sport-matches',
        //---
        categories: prefix + '/prematch/categories',
        //---
        tournaments: prefix + '/prematch/tournaments',
        popularTournaments: prefix + '/prematch/popular-tournaments',
        //---
        marketGroups: prefix + '/prematch/market-groups',
        markets: prefix + '/prematch/markets',
        customMarketGroups: prefix + '/prematch/custom-market-groups',
        customMarkets: prefix + '/prematch/custom-markets',
        allMarkets: prefix + '/prematch/all-markets',
        allCustomMarkets: prefix + '/prematch/all-custom-markets',
        //---
        topMatches: prefix + '/prematch/top-matches',
        favortieMatches: prefix + '/prematch/favorite-matches',
        matches: prefix + '/prematch/tournament-matches',
        match: prefix + '/prematch/match',
        matchTV: prefix + '/prematch/tv-channels',
        categoryMatches: prefix + '/prematch/category-matches',
        // ---
        outrights: prefix + '/outright/events',
        outrightBets: prefix + '/outright/bets',
        // ---
        matchPlayers: prefix + '/static/match-players',
        // ---
        coupons: {
            list: prefix + '/coupon',
            add: prefix + '/coupon/add',
            addVaix: prefix + '/coupon/add2',
            clear: prefix + '/coupon/clear',
            remove: prefix + '/coupon/remove',
            removeVaix: prefix + '/coupon/remove2',
            payout: prefix + '/coupon/payout',
            payoutSingle: prefix + '/coupon/payout-single',
            save: prefix + '/coupon/save',
            saveSingle: prefix + '/coupon/save-single',
        },

        vaix: {
            getRecommendation: prefix + '/vaix/betslips-recommended',
            sendStats: prefix + '/vaix/tracker',
        },

        search: prefix + '/prematch/search',

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                     Live betting                                     ##
        // ##                                                                                      ##
        // ##========================================================================================

        liveMatches: prefix + '/live/matches',
        liveMarketGroups: prefix + '/live/market-groups',
        liveAllMarkets: prefix + '/live/all-markets',
        liveMarkets: prefix + '/live/markets',
        liveCalendar: prefix + '/live/calendar',
        liveSportsList: prefix + '/live/sports',
        liveStream: prefix + '/live/stream',
    },
    promo: {
        getPromo: prefix + '/global/bonus',
        sendPromo: prefix + '/account/bonus',
    },
    logs: {
        errorLogger: '/weblog',
    },
};

export default apiUrls;
