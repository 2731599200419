import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {trans} from "../../../../common/utils";
import {LLBtn2} from "../../../../components";

import {currencySymbols} from "../../../../configs";


const SecondModal = ({store}) => {

        const viewSize = store.site.status.viewSize;
        const setTutorialStep = store.slotsBattles.setTutorialStep;
        const setBackDropClosable = store.site.status.setBackDropClosable;
        const currency = store.user.currency;
        const close = store.modal.close;
        const tutorialOnCloseGoTo = store.slotsBattles.tutorialOnCloseGoTo;
        const router = store.router;

        useEffect(() => {
            setBackDropClosable(false);
        }, []);

        const FreeSpinButtonInstruction = ({tipPosition}) => (
            <div className="battle-tutorial-modal__freespin-instruction">
                <div className="battle-tutorial-modal__freespin-instruction-tip">
                    <div
                        className={`battle-tutorial-modal__parent-indicator battle-tutorial-modal__parent-indicator--${tipPosition}`}/>
                    <p><span>2.</span> {trans('SLOT_BATTLE__TUTORIAL_STEP_4_TIP_2')}</p>
                </div>
                <div className="battle-tutorial-modal__freespin-instruction-btn">
                    <p>{trans('SLOT_BATTLE__TUTORIAL_STEP_4_BUY')}</p>
                    <p>{'100' + ' ' + currencySymbols[currency]}</p>
                </div>
            </div>
        );

        const FreeSpinBetInstruction = ({tipPosition}) => (
            <div className="battle-tutorial-modal__bet-instruction">
                <div className="battle-tutorial-modal__bets-count">
                    <i className={cx(
                        'battle-tutorial-modal__bets-count-icon',
                        'battle-tutorial-modal__bets-count-icon--small',
                        viewSize === 'desktop' ? 'doruk-minus' : 'doruk-coins'
                    )} />
                    <i className={cx(
                        'battle-tutorial-modal__bets-count-icon',
                        'battle-tutorial-modal__bets-count-icon--big',
                        viewSize === 'desktop' ? 'doruk-refresh' : 'doruk-auto-refresh'
                    )} />
                    <i className={cx(
                        'battle-tutorial-modal__bets-count-icon',
                        'battle-tutorial-modal__bets-count-icon--small',
                        viewSize === 'desktop' ? 'doruk-plus' : 'doruk-refresh'
                    )} />
                </div>
                <div className={cx(
                    'battle-tutorial-modal__freespin-instruction-tip',
                    'battle-tutorial-modal__freespin-instruction-tip--bottom',
                )}>
                    <div className={cx(
                        'battle-tutorial-modal__parent-indicator',
                        `battle-tutorial-modal__parent-indicator--${tipPosition}`
                    )}/>
                    <p><span>1.</span> {trans('SLOT_BATTLE__TUTORIAL_STEP_4_TIP_1')}</p>
                </div>
            </div>
        );

        const Instraction = () => (
            <div className="battle-tutorial-modal__text-instruction">
                {trans('SLOT_BATTLE__TUTORIAL_STEP_4_TEXT')}
            </div>
        )

        const InstractionImg = () => (
            <div className="battle-tutorial-game-instruction-img"/>
        )

        return (
            <div className={`battle-tutorial-modal battle-tutorial-second-modal battle-tutorial-modal--${viewSize}`}>
                {viewSize === 'desktop' ? <div className="battle-tutorial-modal__instruction-container">
                        <div className="battle-tutorial-modal__part">
                            <FreeSpinButtonInstruction tipPosition='bottom'/>
                            <LLBtn2
                                onClick={() => {
                                    setTutorialStep(0);
                                    close();
                                    router.push(tutorialOnCloseGoTo);
                                }}
                                customClasses="slots-battles__btn--accent"
                            >
                                {trans("SLOT_BATTLE__TUTORIAL_GOT_IT")}
                            </LLBtn2>
                        </div>
                        <div className="battle-tutorial-modal__part">
                            <Instraction/>
                            <InstractionImg/>
                            <FreeSpinBetInstruction tipPosition={'right'} />
                        </div>
                    </div>
                    :
                    <>
                        <Instraction/>
                        <InstractionImg/>
                        <FreeSpinButtonInstruction tipPosition='left'/>
                        <FreeSpinBetInstruction tipPosition={'top'}/>
                        <LLBtn2
                            onClick={() => {
                                setTutorialStep(0);
                                close();
                                router.push(tutorialOnCloseGoTo);
                            }}
                            customClasses="slots-battles__btn--accent"
                        >
                            {trans("SLOT_BATTLE__TUTORIAL_GOT_IT")}
                        </LLBtn2>
                    </>
                }
            </div>
        );
    }
;

export default inject('store')(observer(SecondModal));