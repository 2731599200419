import {values} from 'mobx';
import {types, flow, getParent, getRoot} from 'mobx-state-tree';
import {orderBy} from 'lodash';

import {llNotifier} from '../../../../components';
import api from '../../../../common/api';
import {llattempt, formatNumber, trans} from '../../../../common/utils';


const CouponSystem = types.model('Coupons', {
    ticketsInCombo: types.integer,
    betslips: types.integer,
});

const CouponItem = types
    .model('Coupon', {
        id: types.identifierNumber,
        categoryId: types.integer,
        competitor1: types.string,
        competitor2: types.string,
        matchDate: types.Date,
        matchId: types.integer,
        matchType: types.string,
        maximumMatch: types.integer,
        minimumMatch: types.integer,
        odds: types.number,
        oddsType: types.string,
        oddsTypeSelection: types.string,
        sportId: types.integer,
        tournamentId: types.integer,
        webCouponId: types.integer,
        matchName: types.maybeNull(types.string),
        customOdds: types.maybeNull(types.number),
        rowChar: types.maybeNull(types.string),
        isCombined: false,
        betAmount: 0,
        payout: types.maybeNull(types.number),
        maximumStake: types.maybeNull(types.number),
    })
    .actions((self) => ({
        update(coupon) {
            self.categoryId = coupon.CategoryID;
            self.competitor1 = coupon.Competitor1;
            self.competitor2 = coupon.Competitor2;
            self.customOdds = coupon.CustomOdds;
            self.matchDate = new Date(coupon.MatchDate.replace(' ', 'T') + '+03:00');
            self.matchId = coupon.MatchID;
            self.matchType = coupon.MatchType;
            self.matchName = coupon.Match;
            self.maximumMatch = coupon.MaximumMatch;
            self.minimumMatch = coupon.MinimumMatch;
            self.odds = coupon.Odds;
            self.oddsType = coupon.OddsType;
            self.oddsTypeSelection = coupon.OddsTypeSelection.toString();
            self.sportId = coupon.SportID;
            self.tournamentId = coupon.TournamentID;
            self.webCouponId = coupon.WebCouponID;
            self.isCombined = !!coupon.IsCombined;
            self.maximumStake = coupon.maximumStake || null;
        },

        remove: flow(function* fetch() {
            const p = getParent(self, 2);
            const activeMatch = getRoot(self)?.router.prematchRouterMatchInstance;
            p.setFetching(true);
            if (p.isCustom && activeMatch) {
                activeMatch.setCustomBetsFetching(true);
            }
            const params = {
                oddsId: self.id,
                custom: !!self.customOdds,
                source: 1, //there was dataSource based something some time ago...
            };
            return yield llattempt(
                () => {
                    return api.betting.removeCoupon(params).then((r) => {
                        if (r.success) {
                            if (p.isCustom && activeMatch) {
                                p.isCustom && activeMatch.setCustomBetsFetching(true);
                                activeMatch.setLastCustomMarketGroupsFetchTime(null);
                                p.deleteCoupon(self.id);
                                p.isCustom && activeMatch.initializeAllMarketsInstruction().then(() => {
                                    p.setFetching(false);
                                    activeMatch.setCustomBetsFetching(false);
                                });
                            } else {
                                p.setFetching(false);
                                p.deleteCoupon(self.id);
                            }
                            p._processNewData(r.data, false);
                        } else {
                            p.setFetching(false);
                            if (p.isCustom && activeMatch) {
                                activeMatch.setLastCustomMarketGroupsFetchTime(null);
                                activeMatch.setCustomBetsFetching(false);
                            }
                            llNotifier({
                                message: r.data.msg[0] || r.data.error || 'Error removing coupon',
                                type: 'error',
                            });
                        }
                        return r;
                    })
                },
                {
                    msg: 'Error removing coupon',
                    at: 'CouponItem.remove',
                    withParams: params,
                    onError: () => {
                        getParent(self, 2).setFetching(false);
                        activeMatch && activeMatch.setCustomBetsFetching(false);
                    },
                }
            );
        }),
        setBetAmount(val, isMainAmountChange = false) {
            if (val > 0) {
                if (val !== self.betAmount) {
                    self.betAmount = val;
                    if (!isMainAmountChange) {
                        getParent(self, 2).calculateCouponsPayout([{
                            oddsId: self.id,
                            amount: val,
                        }]);
                    }
                }
            } else {
                self._setCouponPayout(0);
                self.betAmount = 0;
            }
        },
        _setCouponPayout(payout) {
            self.payout = payout;
        },
    }))
    .views((self) => {
        return {
            get formatedTimeStart() {
                const year = self.matchDate.getFullYear();
                const month = ('0' + (self.matchDate.getMonth() + 1)).slice(-2);
                const day = ('0' + self.matchDate.getDate()).slice(-2);
                const hour =
                    self.matchDate.getHours() >= 10
                        ? self.matchDate.getHours()
                        : `0${self.matchDate.getHours()}`;
                const minutes =
                    self.matchDate.getMinutes() >= 10
                        ? self.matchDate.getMinutes()
                        : `0${self.matchDate.getMinutes()}`;
                return `${day}.${month}.${year} ${hour}:${minutes}`;
            },
            get formattedOddsValue() {
                return formatNumber(self.odds, 2, getRoot(self).user.language);
            },
            get isMaxStakeExceeded() {
                return self.maximumStake && (self.maximumStake < self.betAmount);
            }
        };
    });

const CouponSubItem = types
    .model('CouponSubItem', {
        id: types.identifierNumber,
        rowChars: types.string,
        rate: types.number,
        maxPayout: types.number,
    })
    .views((self) => {
        return {
            get formattedMaxPayout() {
                return formatNumber(self.maxPayout, 2, getRoot(self).user.language);
            },
            get formattedRate() {
                return formatNumber(self.rate, 2, getRoot(self).user.language);
            },
        };
    });

const SavedCouponData = types
    .model('SavedCouponData', {
        id: 0,
        canPrint: types.maybeNull(types.boolean),
        isCustom: types.maybeNull(types.boolean),
        stakeTotal: types.maybeNull(types.number),
        couponDateTime: types.maybeNull(types.Date),
        payoutTotal: types.maybeNull(types.number),
        totalRate: types.maybeNull(types.number),
        selectedSystems: types.array(types.maybeNull(types.string)),
        coupons: types.map(CouponItem),
        couponSub: types.map(CouponSubItem),
    })
    .volatile((self) => ({
        error: null,
    }))
    .actions((self) => ({
        setError(error) {
            self.error = error;
        },
        _putCoupons(coupons) {
            coupons.map((coupon, i) => {
                self.coupons.put({
                    id: i,
                    categoryId: 0,
                    competitor1: '',
                    competitor2: '',
                    matchDate: new Date(coupon.MatchDate.replace(' ', 'T') + '+03:00'),
                    matchId: i,
                    matchType: coupon.MatchType,
                    maximumMatch: 0,
                    minimumMatch: 0,
                    odds: coupon.Odds,
                    oddsType: coupon.OddsType,
                    oddsTypeSelection: coupon.OddsTypeSelection.toString(),
                    sportId: 0,
                    tournamentId: 0,
                    webCouponId: 0,
                    matchName: coupon.Match,
                    customOdds: null,
                    rowChar: coupon.RowChar,
                    isCombined: !!coupon.IsCombined,
                });
            });
        },
    }))
    .views((self) => {
        return {
            get formattedStakeTotal() {
                return formatNumber(self.stakeTotal, 2, getRoot(self).user.language);
            },
            get formattedPayoutTotal() {
                return formatNumber(self.payoutTotal, 2, getRoot(self).user.language);
            },
            get formattedTotalRate() {
                return formatNumber(self.totalRate, 2, getRoot(self).user.language);
            },
            get formatedCouponDateTime() {
                const year = self.couponDateTime.getFullYear();
                const month = ('0' + (self.couponDateTime.getMonth() + 1)).slice(-2);
                const day = ('0' + self.couponDateTime.getDate()).slice(-2);
                const hour =
                    self.couponDateTime.getHours() >= 10
                        ? self.couponDateTime.getHours()
                        : `0${self.couponDateTime.getHours()}`;
                const minutes =
                    self.couponDateTime.getMinutes() >= 10
                        ? self.couponDateTime.getMinutes()
                        : `0${self.couponDateTime.getMinutes()}`;
                return `${day}.${month}.${year} ${hour}:${minutes}`;
            },
            get list() {
                return orderBy(values(self.coupons), ['matchDate'], ['asc']);
            },
            get coupondetList() {
                return orderBy(self.list, ["rowChar"], "asc")
            },
            get couponSubList() {
                return values(self.couponSub);
            }
        };
    });

const Coupons = types
    .model('Coupons', {
        system: types.maybeNull(types.array(CouponSystem)), //array of possible system bets
        selectedSystems: types.maybeNull(types.array(types.integer)), //array of user's chosen system bets
        rate: types.maybeNull(types.number), //multiplyer to show how much user will get
        coupons: types.map(CouponItem), //array of user coupons
        payoutAmount: types.maybeNull(types.number), //how much user will get in monye
        betAmount: 0, //how much user will bet
        fetching: false, //is coupons data currently loading (for disabling buttons etc.)
        payoutfetching: false, //is payout data currently loading (for hiding old payout value)
        isCustom: false, //if coupons is custom bets
        needClearFetching: true,
        savedCouponData: types.array(SavedCouponData),
        maximumStake: types.maybeNull(types.number),
    })
    .actions((self) => {
            return {
                //INNER ACTIONS
                _processNewData(data, withClear = true, dataFromPageRefresh = false) {
                    /* Check if coupons is customBetCoupons*/
                    if (data.coupons.length) {
                        if (dataFromPageRefresh) {
                            self.isCustom = !!data.coupons[0].CustomOdds;
                        } else if (data.coupons[0].CustomOdds) {
                            if (!self.isCustom) {
                                withClear = true;
                            }
                            self.isCustom = true;
                        } else {
                            if (self.isCustom) {
                                withClear = true;
                            }
                            self.isCustom = false;
                        }
                    }

                    if (data.coupons.length) {
                        if (withClear) {
                            self.clearCouponsInfo();
                        } else {
                            self.coupons.clear();
                        }
                        self._putCoupons(data.coupons);
                    } else {
                        self.clearCouponsInfo();
                    }
                    data.rate && self._updateRate(data.rate);
                    self._updateMaximumStake(data.maximumStake);
                    if (data.system.length) {
                        self._changeSystems(data.system);
                        self.setSelectedSystems([self.systems[0].ticketsInCombo]);
                    }
                },
                _calculatePayout: flow(function* fetch(betInfo) {
                    self._setPayoutFetching(true);
                    return yield api.betting
                        .getPayout(betInfo)
                        .then((r) => {
                            if (r.success) {
                                self._setPayout(r.data.payout);
                            }
                            self._setPayoutFetching(false);
                            return r;
                        })
                        .catch((e) => {
                            console.error("Can't get payout info", e);
                            self._setPayoutFetching(false);
                        });
                }),
                _putCoupons(coupons) {
                    coupons.map((coupon) => {
                        if (!self.coupons.has(coupon.OddsID)) {
                            self.coupons.put({
                                id: coupon.OddsID,
                                categoryId: coupon.CategoryID,
                                competitor1: coupon.Competitor1,
                                competitor2: coupon.Competitor2,
                                matchDate: new Date(coupon.MatchDate.replace(' ', 'T') + '+03:00'),
                                matchId: coupon.MatchID,
                                matchType: coupon.MatchType,
                                maximumMatch: coupon.MaximumMatch,
                                minimumMatch: coupon.MinimumMatch,
                                odds: coupon.Odds,
                                oddsType: coupon.OddsType,
                                oddsTypeSelection: coupon.OddsTypeSelection.toString(),
                                sportId: coupon.SportID,
                                tournamentId: coupon.TournamentID,
                                webCouponId: coupon.WebCouponID,
                                matchName: coupon.Match,
                                customOdds: coupon.CustomOdds,
                                betAmount: self.betAmount
                            });
                        } else {
                            self.coupons.get(coupon.OddsID).update(coupon);
                        }
                    });
                },
                _changeSystems(system) {
                    self.system = [];
                    system.map((sysItem) => {
                        //{R: "1", Comb: "1"}
                        self.system.push(
                            CouponSystem.create({
                                ticketsInCombo: sysItem.R,
                                betslips: sysItem.Comb,
                            })
                        );
                    });
                },
                _updateRate(rate) {
                    self.rate = rate;
                },
                _updateMaximumStake(maximumStake) {
                    self.maximumStake = maximumStake;
                },
                _setPayout(val) {
                    self.payoutAmount = val;
                },
                _setPayoutFetching(status) {
                    self.payoutfetching = status;
                },

                //EXTERNAL ACTIONS
                getCouponsList: flow(function* fetch() {
                    self.setFetching(true);
                    return yield llattempt(
                        () =>
                            api.betting.getCoupons().then((r) => {
                                if (r.success && r.data) {
                                    self._processNewData(r.data, true, true);
                                    self.setFetching(false);
                                } else {
                                    llNotifier({
                                        message: 'Error',
                                        type: 'error',
                                    });
                                    console.error(
                                        'Error getting player coupons with result:', r
                                    );
                                    self.setFetching(false);
                                }
                                return r;
                            }),
                        {
                            msg: 'Error getting list of coupons',
                            at: 'Coupons.getCouponsList',
                            withParams: null,
                            onError: () => {
                                self.setFetching(false);
                            },
                        }
                    );
                }),

                add: flow(function* fetch(betInfo) {
                    getRoot(self).user.setCouponsPanelView('coupons')
                    self.savedCouponData = [];
                    self.setFetching(true);
                    self.setNeedClearFetching(true);
                    const activeMatch = getRoot(self)?.router.prematchRouterMatchInstance;
                    activeMatch && activeMatch.setCustomBetsFetching(true);
                    if (self.isCustom && betInfo.custom && self.list.find(coupon => coupon.matchId.toString() !== activeMatch?.id)) {
                        getRoot(self).modal.setModal({
                            active: true,
                            maskBackground: true,
                            showHeader: true,
                            currentKey: 'clear-betslip',
                            betInfo: betInfo,
                            customClasses: 'custom-bets__clear-modal',
                            title: trans('Warning'),
                        });
                        self.setFetching(false);
                        if (self.isCustom && activeMatch) {
                            activeMatch.setCustomBetsFetching(false);
                        }
                        return false;
                    } else {
                        return yield llattempt(
                            () =>
                                api.betting.addCoupon(betInfo).then((r) => {
                                    if (r.success && r.data) {
                                        self._processNewData(r.data, false);
                                        if (self.isCustom && activeMatch) {
                                            activeMatch.setLastCustomMarketGroupsFetchTime(null);
                                            activeMatch.initializeAllMarketsInstruction().then(() => {
                                                self.setFetching(false);
                                                activeMatch.setCustomBetsFetching(false);
                                            });
                                        } else {
                                            self.setFetching(false);
                                            activeMatch && activeMatch.setCustomBetsFetching(false);
                                            activeMatch && activeMatch.setLastCustomMarketGroupsFetchTime(null);
                                        }
                                    } else {
                                        if (r.data?.error === 'UNAVALABLE_FOR_COMBINE3') {
                                            getRoot(self).modal.setModal({
                                                active: true,
                                                maskBackground: true,
                                                showHeader: true,
                                                currentKey: 'clear-betslip',
                                                betInfo: betInfo,
                                                customClasses: 'custom-bets__clear-modal',
                                                title: trans('Warning'),
                                            });
                                            self.setFetching(false);
                                            if (self.isCustom && activeMatch) {
                                                activeMatch.setCustomBetsFetching(false);
                                            }
                                        } else {
                                            let msg = 'Error getting player coupons';

                                            const errorHandling = (errorText) => {
                                                if (errorText.includes('The market is unavailable:')) {
                                                    msg = 'BET_BUILDER_UNAVAILABLE_MARKET';
                                                } else if (errorText.includes('No data for the event:')) {
                                                    msg = 'BET_BUILDER_NO_DATA_FOR_EVENT';
                                                } else if (errorText.includes('Error adding coupon')) {
                                                    msg = 'MAX_EVENTS_REACHED';
                                                } else if (self.isCustom) {
                                                    msg = 'BET_BUILDER_COMMON_ERROR';
                                                } else {
                                                    msg = errorText
                                                }
                                            }
                                            if (r.data?.error) {
                                                msg = r.data.error
                                            }
                                            if (r.data?.msg && typeof (r.data.msg) === 'object' && r.data?.msg[0]) {
                                                errorHandling(r.data.msg[0]);
                                            } else if (r.data?.msg && typeof (r.data.msg) === 'string') {
                                                errorHandling(r.data.msg);
                                            }
                                            llNotifier({
                                                message: msg,
                                                type: 'error',
                                                withTranslation: true,
                                            });
                                            console.error(
                                                'Error getting player coupons at "add" with result:',
                                                r
                                            );
                                        }
                                        self.needClearFetching && self.setFetching(false);
                                        activeMatch && activeMatch.setCustomBetsFetching(false);
                                    }
                                    return r;
                                }),
                            {
                                msg: 'Error adding coupon',
                                at: 'Coupons.add',
                                withParams: null,
                                onError: () => {
                                    self.setFetching(false);
                                    activeMatch && activeMatch.setCustomBetsFetching(false);
                                },
                            }
                        )
                    }
                }),

                addVaix: flow(function* fetch(betInfo) {
                    getRoot(self).user.setCouponsPanelView('coupons')
                    self.savedCouponData = [];
                    self.setFetching(true);
                    return yield llattempt(
                        () =>
                            api.betting.addVaix(betInfo).then((r) => {
                                if (r.success && r.data) {
                                    self._processNewData(r.data, false);
                                    self.setFetching(false);
                                } else {
                                    let msg = 'Error getting player coupons';
                                    if (r.data?.error) {
                                        msg = r.data.error
                                    }
                                    if (r.data?.msg && typeof (r.data.msg) === 'object' && r.data?.msg[0]) {
                                        msg = r.data.msg[0]
                                    } else if (r.data?.msg && typeof (r.data.msg) === 'string') {
                                        msg = r.data.msg;
                                        // For Bet Builder
                                        if (r.data.msg.includes('The market is unavailable:')) {
                                            msg = 'BET_BUILDER_UNAVAILABLE_MARKET';
                                        } else if (r.data.msg.includes('No data for the event:')) {
                                            msg = 'BET_BUILDER_NO_DATA_FOR_EVENT';
                                        }
                                    }
                                    llNotifier({
                                        message: msg,
                                        type: 'error',
                                    });
                                    self.setFetching(false);
                                }
                                return r;
                            }),
                        {
                            msg: 'Error adding coupons',
                            at: 'Coupons.addVaix',
                            withParams: null,
                            onError: () => {
                                self.setFetching(false);
                            },
                        }
                    )
                }),

                removeVaix: flow(function* fetch({oddsIds} = {}) {
                    self.setFetching(true);
                    return yield llattempt(
                        () => {
                            return api.betting.removeVaix({oddsId: oddsIds}).then((res) => {
                                if (res.success) {
                                    self._processNewData(res.data, false);
                                    self.setFetching(false);
                                }
                            })
                        },
                        {
                            msg: 'Error removing coupon',
                            at: 'Coupons.removeVaix',
                            withParams: {},
                            onError: () => {
                                self.setFetching(false);
                            },
                        }
                    );
                }),

                clear: flow(function* fetch(needClearFetching = true) {
                    const activeMatch = getRoot(self)?.router.prematchRouterMatchInstance;
                    if (self.isCustom && activeMatch) {
                        activeMatch.setCustomBetsFetching(true);
                        activeMatch.setLastCustomMarketGroupsFetchTime(null);
                    } else {
                        self.setFetching(true);
                    }
                    return yield llattempt(
                        () =>
                            api.betting.clearCoupons().then((r) => {
                                if (r.success) {
                                    self.clearCouponsInfo();
                                    const activeMatch = getRoot(self)?.router.prematchRouterMatchInstance;
                                    activeMatch && activeMatch.setLastCustomMarketGroupsFetchTime(null);
                                } else {
                                    let msg = 'Error';
                                    if (r.data?.error) {
                                        msg = r.data.error
                                    }
                                    if (r.data?.msg[0]) {
                                        msg = r.data.msg[0]
                                    }
                                    llNotifier({
                                        message: msg,
                                        type: 'error',
                                    });
                                    console.error(
                                        'Error clearing player coupons with result:',
                                        r
                                    );
                                }
                                (self.isCustom && activeMatch) && activeMatch.initializeAllMarketsInstruction().then(() => {
                                    activeMatch.setCustomBetsFetching(false);
                                });
                                needClearFetching && self.setFetching(false);
                                return r;
                            }),
                        {
                            msg: 'Error clearing coupons',
                            at: 'Coupons.clear',
                            withParams: null,
                            onError: () => {
                                self.setFetching(false);
                            },
                        }
                    );
                }),
                save: flow(function* fetch() {
                    const params = {
                        accept: self.isCustom ? true : getRoot(self).user.acceptChanges,
                        amount: self.betAmount,
                        system: [...self.selectedSystems],
                        custom: self.isCustom,
                    };

                    self.setFetching(true);
                    return yield llattempt(
                        () =>
                            api.betting.saveCoupons(params).then((r) => {
                                if (r.success) {
                                    self.pushCouponSavedData(r.data);
                                    const user = getRoot(self).user;
                                    user.userBets.activeBets.resetFetchTimeAndLoading();
                                    user.userBets.betHistory.resetFetchTimeAndLoading();
                                    user.setActiveBetsCount(user.activeBetsCount + 1);
                                    user.setActiveBetsCountWasForceUpdated(true);
                                }
                                self.setFetching(false);
                                return r;
                            }),
                        {
                            msg: 'Error placing bet',
                            at: 'Coupons.save',
                            withParams: params,
                            onError: () => {
                                self.setFetching(false);
                            },
                        }
                    );
                }),
                saveSingle: flow(function* fetch() {
                    const params = [];
                    const accepted = self.isCustom ? true : getRoot(self).user.acceptChanges;
                    self.couponsList({isCustom: self.isCustom}).forEach(coupon => {
                        params.push({
                            accepted: accepted,
                            amount: coupon.betAmount,
                            oddsId: coupon.id
                        })
                    })

                    self.setFetching(true);
                    return yield llattempt(
                        () =>
                            api.betting.saveCouponsSingle(params).then((r) => {
                                if (r.success) {
                                    let count = 0;
                                    r.data.forEach(item => {
                                        if (item.success) {
                                            count++
                                            self.pushCouponSavedData(item.data)
                                        } else {
                                            self.pushCouponSavedData(item)
                                        }
                                    });
                                    const user = getRoot(self).user;
                                    user.userBets.activeBets.resetFetchTimeAndLoading();
                                    user.userBets.betHistory.resetFetchTimeAndLoading();
                                    user.setActiveBetsCount(user.activeBetsCount + count);
                                    user.setActiveBetsCountWasForceUpdated(true);
                                }
                                self.setFetching(false);
                                return r;
                            }),
                        {
                            msg: 'Error placing single bet',
                            at: 'Coupons.saveSingle',
                            withParams: params,
                            onError: () => {
                                self.setFetching(false);
                            },
                        }
                    );
                }),
                pushCouponSavedData(data) {
                    if (data) {
                        let newSavedData = {};
                        if (data.coupon) {
                            newSavedData = {
                                id: data.coupon.CouponID,
                                canPrint: data.canprint,
                                isCustom: data.coupon.IsCustom,
                                couponDateTime: new Date(data.coupon.CouponDateTime.replace(' ', 'T') + '+03:00'),
                                stakeTotal: data.coupon.StakeTotal,
                                payoutTotal: data.coupon.PayoutTotal,
                                totalRate: data.coupon.TotalRate,
                            }
                        } else {
                            newSavedData = { id: data.oddsId};
                        }
                        const newIndex = self.savedCouponData?.length || 0;
                        self.savedCouponData.push(newSavedData);
                        if (!data.coupon) {
                            self.savedCouponData[newIndex].setError(data);
                        }
                        if (data.coupondet?.length) {
                            self.savedCouponData[newIndex]._putCoupons(data.coupondet);
                        }
                        if (data.couponsub?.length) {
                            self.selectedSystems = [];
                            data.couponsub.map((item, i) => {
                                const numberOfChars = item.RowChars.split(',');
                                const system = numberOfChars.length + '/' + data.coupondet?.length;
                                if (!self.savedCouponData[newIndex].selectedSystems.includes(system)) {
                                    self.savedCouponData[newIndex].selectedSystems.push(system)
                                }
                                self.savedCouponData[newIndex].couponSub.put({
                                    id: i,
                                    rowChars: item.RowChars,
                                    rate: item.Rate,
                                    maxPayout: item.MaxPayout,
                                })
                            });
                        }
                    }
                },
                clearCouponsInfo() {
                    self.system = [];
                    self.rate = 0;
                    values(self.coupons).map((coupon) => {
                        self.coupons.delete(coupon.id);
                    });
                    self.setBetAmount(0);
                    self.savedCouponData = [];
                },
                deleteCoupon(id) {
                    self.coupons.delete(id);
                },
                setBetAmount(val) {
                    if (val > 0) {
                        if (val !== self.betAmount) {
                            self.betAmount = val;
                            self._calculatePayout({
                                amount: val,
                                system: self.isCustom ? [self.customCouponsList.length] : self.selectedSystems,
                            });
                            const params = [];
                            self.couponsList({isCustom: self.isCustom}).forEach(coupon => {
                                coupon.setBetAmount(val, true);
                                params.push({
                                    oddsId: coupon.id,
                                    amount: val
                                })
                            })
                            self.calculateCouponsPayout(params);
                        }
                    } else {
                        self._setPayout(0);
                        self.betAmount = 0;
                    }
                },
                calculateCouponsPayout: flow(function* fetch(betInfo) {
                    self._setPayoutFetching(true);
                    yield api.betting
                        .getCouponPayout(betInfo)
                        .then((r) => {
                            if (r.success) {
                                r.data.forEach(item => {
                                    const coupon = self.coupons.get(parseInt(item.oddsId));
                                    if (coupon) {
                                        coupon._setCouponPayout(parseFloat(item.payout));
                                    }
                                })
                            }
                            self._setPayoutFetching(false);
                        })
                        .catch((e) => {
                            console.error("Can't get payout info", e);
                            self._setPayoutFetching(false);
                        });
                }),
                setSelectedSystems(arr) {
                    self.selectedSystems = self.isCustom ? [self.customCouponsList.length] : arr;
                    if (arr.length && self.betAmount) {
                        arr?.length &&
                        self._calculatePayout({
                            amount: self.betAmount,
                            system: self.isCustom ? [self.customCouponsList.length] : arr,
                        });
                    } else {
                        self._setPayout(0);
                    }
                },
                setFetching(status) {
                    self.fetching = status;
                },
                setNeedClearFetching(flag) {
                    self.needClearFetching = flag;
                },
                betErrorHandler(response, errorText) {
                    const lang = getParent(self, 2).language;
                    if (response?.data?.error) {
                        switch (response.data.error) {
                            case 'SAVE_ERROR4':
                                errorText = errorText.replace('#1', response.data.min);
                                break;
                            case 'SAVE_ERROR9':
                            case 'SAVE_ERROR10':
                                errorText = errorText.replace(
                                    '#1',
                                    formatNumber(
                                        parseFloat(response.data.stake) *
                                        parseFloat(response.data.combination),
                                        2,
                                        lang
                                    )
                                );
                                break;
                            case 'SAVE_ERROR13':
                                errorText = errorText.replace(
                                    '#1',
                                    `<p>${formatNumber(
                                        Math.floor(parseFloat(response.data.maxStake) * 100) / 100,
                                        2,
                                        lang
                                    ) + ' ' + response.data.currency}</p>`
                                );
                                break;
                            case 'SAVE_ERROR13_1':
                                errorText = `<p><b>${trans('Maximum Payout')}</b> : ${trans('SAVE_ERROR13').replace(
                                    '#1',
                                    `<span>${formatNumber(
                                        Math.floor(parseFloat(response.data.maxStake) * 100) / 100,
                                        2,
                                        lang
                                    ) + ' ' + response.data.currency}</span>`
                                )}</p>`;
                                break;
                            case 'SAVE_ERROR14':
                                const diff = parseFloat(response.data.event.StakeLimit) - parseFloat(response.data.event.StakeTotal);
                                errorText = errorText.replace(
                                    '#1',
                                    `${response.data.event.Match}: ${formatNumber((diff > 0 ? diff : 0),
                                        2,
                                        lang
                                    )} ${getRoot(self).user.currency}`
                                );
                                break;
                            case 'SAVE_ERROR14_2':
                                errorText = `<p>${trans('SAVE_ERROR14_2').replace(
                                    '{E#1}',
                                    `<b>${response.data.event.Match}(${response.data.event.OddsType})</b>`
                                ).replace(
                                    '{E#2}',
                                    `<b>${formatNumber(
                                        parseFloat(response.data.event.StakeLimit) - parseFloat(response.data.event.StakeTotal),
                                        2,
                                        lang
                                    )} ${getRoot(self).user.currency}</b>`
                                )}</p>`;
                                break;
                            case 'SAVE_ERROR15':
                                errorText = errorText.replace('#1', response.data.event.Match);
                                break;
                            case 'SAVE_ERROR16':
                                errorText = errorText.replace(
                                    '#1',
                                    response.data.match
                                );
                                errorText = errorText.replace(
                                    '#2',
                                    formatNumber(parseFloat(response.data.new), 2, lang)
                                );
                                errorText = errorText.replace('#3', response.data.match);
                                break;
                            case 'SAVE_ERROR17':
                                errorText = errorText.replace('#1', response.data.event.Match);
                                break;
                            case 'SAVE_ERROR22':
                                errorText = errorText.replace(
                                    '#1',
                                    response.data.suspended ? `<p>${response.data.suspended.join('</p><p>')}</p>` : ''
                                );
                                break;
                            case 'SAVE_ERROR23':
                                errorText = errorText.replace('#1', response.data.match ? `<p>${response.data.match}</p>` : '');
                                break;
                            case 'SAVE_ERROR24':
                                errorText = errorText.replace('#1', response.data.match ? `<p>${response.data.match.join('</p><p>')}</p>` : '');
                                break;
                            case 'MTS_SAVE_ERROR':
                                errorText = errorText.replace('#1', response.data.code);
                                break;
                            case 'MTS_SAVE_ERROR1':
                            case 'MTS_SAVE_ERROR2':
                            case 'MTS_SAVE_ERROR3':
                                let param1 = '';
                                response.data.selections.map((item) => {
                                    param1 += `${item} `;
                                    return param1;
                                });
                                if (response.data.msg) {
                                    errorText = response.data.msg;
                                }
                                errorText = errorText.replace('#1', param1);
                                break;
                            case 'MTS_SAVE_ERROR421':
                                let params = '';
                                response.data.matches.map((item) => {
                                    params += `${item} `;
                                    return params;
                                });
                                if (response.data.msg) {
                                    errorText = response.data.msg;
                                }
                                errorText = errorText.replace('#1', params);
                                break;
                            default:
                                break;
                        }
                    }
                    return errorText;
                }
                ,
            };
        }
    )
    .views((self) => {
        return {
            couponsList({isCustom} = {}) {
                if (self.isCustom === isCustom) {
                    return values(self.coupons).map((coupon) => {
                        return {
                            ...coupon,
                            oddsValue: coupon.formattedOddsValue,
                            formatedTimeStart: coupon.formatedTimeStart,
                            setBetAmount: coupon.setBetAmount,
                            isMaxStakeExceeded: coupon.isMaxStakeExceeded,
                            remove: coupon.remove,
                        };
                    });
                } else {
                    return []
                }
            },
            get list() {
                return self.couponsList({isCustom: self.isCustom});
            },
            get basicCouponsList() {
                return self.couponsList({isCustom: false});
            },
            get customCouponsList() {
                return self.couponsList({isCustom: true});
            },
            get formattedRate() {
                return formatNumber(
                    self.rate ? self.rate : 0,
                    2,
                    getParent(self, 2).language
                );
            },
            get payout() {
                const user = getParent(self, 1);
                return formatNumber(
                    self.payoutAmount ? self.payoutAmount : 0,
                    2,
                    user.language,
                    user.currency
                );
            },
            get systems() {
                return self.system
                    ? orderBy([...self.system], ['ticketsInCombo'], 'desc')
                    : [];
            },
            get betslipsCount() {
                let summ = 0;
                self.selectedSystems?.length &&
                self.selectedSystems.map((ps) => {
                    const result = self.system.find(
                        (sysItem) => sysItem.ticketsInCombo === ps
                    );
                    if (result) {
                        summ += result.betslips;
                    }
                });
                return summ;
            },
            get couponCount() {
                return values(self.coupons).length;
            },
            get isMaxStakeExceeded() {
                return self.maximumStake && (self.maximumStake < self.betAmount);
            },
            get isAllMultiSingleHasMainAmount() {
                return !self.couponsList({isCustom: self.isCustom}).find(coupon => coupon.betAmount !== self.betAmount)
            },
            get totalCouponsAmount() {
                let amount = 0;
                self.couponsList({isCustom: self.isCustom}).forEach(coupon => amount = amount + coupon.betAmount);
                return amount;
            },
            get totalCouponsPayout() {
                let amount = 0;
                self.couponsList({isCustom: self.isCustom}).forEach(coupon => amount = amount + coupon.payout);
                return amount;
            },
            get isSingleOnly() {
                return !!self.list.find((item, i) =>
                    !!self.list.find((inner, j) =>
                        (inner.matchId === item.matchId && i !== j && !self.isCustom)
                    )
                );
            },
            checkOkStatus(isMultiSingle = false) {
                const couponWithEmptyAmount = !!values(self.coupons).find(coupon => !coupon.betAmount);
                return !(
                    self.fetching ||
                    self.payoutfetching ||
                    self.selectedSystems.length === 0 ||
                    (!isMultiSingle && self.betAmount === 0) ||
                    (!isMultiSingle && self.isSingleOnly) ||
                    couponWithEmptyAmount
                );
            },
        };
    });

export default Coupons;
