import React from "react";
import cx from 'classnames';

import {LLElementTypeSetter, llNotifier, withCommon} from '../../';
import {API_PREFIX} from "../../../common/api/api-url-config";
import apiUrls from "../../../common/api/api-urls";


const GameButtonBuilder = withCommon({
    store: ({store}) => ({
        isLogged: store.user.isLogged,
        collectActivity: store.user.collectActivity,
        mobileUserAgent: store.user.mobileUserAgent,
        openLogin: store.user.openLogin,
    }),
    isObserver: true,
})(({
        isLogged,
        openLogin,
        collectActivity,
        item,
        mobileUserAgent,
        mode,
        needButton = false,
        children,
        customClasses,
        ...restProps
    }) => {

    const elementAttributes = mobileUserAgent ? {
        href: `${API_PREFIX}${apiUrls.games.openGame}?providerId=${item.providerId}&gameId=${item.iD}&demo=${mode}`,
        target: "_blank",
        rel: "noopener noreferrer",
    } : {
        to: `?launch=${mode}-${item.providerId}-${item.iD}${item.gameHeight ? '-' + item.gameHeight : ''}${item.gameWidth ? '-' + item.gameWidth : ''}`,
    }

    const classNames = needButton ? cx('ll-btn2', customClasses) : cx(customClasses);

    return <LLElementTypeSetter
        {...restProps}
        className={classNames}
        as={mobileUserAgent ? '' : 'NavLink'}
        {...elementAttributes}
        onClick={(e) => {
            if (window.location.href.includes('jackpots')) {
                collectActivity({area: 'jackpots', itemId: item.iD, interactTarget: 'jackpots-game'});
            }
            if (!isLogged && !mode && mobileUserAgent) {
                e.preventDefault();
                openLogin();
            }
            if (mode && !item.hasFunMode) {
                e.preventDefault();
                llNotifier({
                    message: 'GAME_HAS_NO_FUN_MODE',
                    type: 'error',
                });
            }
        }}
    >
        {children}
    </LLElementTypeSetter>
});

export default GameButtonBuilder;