/* eslint-disable react/display-name */
import React from 'react';
import {
    AboutUsPage,
    HomePage,
    SportPage,
    SlotsCasinos,
    SlotsBattles,
    PromoPage,
    ForgotPassword,
    ResetPassword,
    ChangePassword,
    MyAccountPage,
    BetOnGames,
    Twain,
    LiveGames,
    Lottery,
    TvBet,
    ErrorPage,
    ContactUsPage,
    MessagesPage,
    MailVerifyPage,
    Lobby,
} from './../../pages';

const efsanebahisPagesConfig = {
    homePage: {
        name: 'Homepage',
        Component: (props) => <HomePage {...props} />,
        url: '/',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description:
                'EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.',
            keywords:
                'bahis, canlı bahis, bahis sitesi, canlı bahis siteleri, bahis siteleri, casino, poker, tombala, at yarışı',
        },
    },
    livePage: {
        name: 'Live',
        Component: (props) => <SportPage {...props} />,
        url: '/sport/live/:sportId?/:categoryId?/:tournamentId?/:matchId?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description:
                'EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.',
            keywords:
                'canlı bahis, canlı bahis sitesi, en iyi canlı bahis, en güvenilir canlı bahis',
        },
    },
    sportPage: {
        name: 'Prematch',
        Component: (props) => <SportPage {...props} />,
        url: '/sport/:branchId?/:sportId?/:categoryId?/:tournamentId?/:matchId?/:matchQueryParam?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description:
                'EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.',
            keywords:
                'spor bahis, spor bahisleri, spor bahis sitesi, spor bahisleri sitesi',
        },
    },
    casinosPage: {
        name: 'Casino',
        Component: (props) => <SlotsCasinos {...props} gamesType="casino" />,
        url: '/casino/:viewType?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description:
                'EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.',
            keywords:
                'canlı casino, canlı rulet, canlı blackjeack, canlı baccarat, canlı türk ruleti, canlı holdem',
        },
    },
    slotsPage: {
        name: 'Slots',
        Component: (props) => <SlotsCasinos {...props} gamesType="slots" />,
        url: '/slots/:viewType?/:jackpotId?',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description:
                'EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.',
            keywords: 'slot, slot oyna, slot oyunları, canlı slot',
        },
    },
    slotsBattlePage: {
        name: 'SlotsBattle',
        Component: (props) => <SlotsBattles {...props} />,
        url: '/slots-battles/:gameId?',
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Slot Battle',
            description:
                "Slot Battle Türkiye'de ilk",
            keywords: '',
        },
    },
    forgotPassword: {
        name: 'Forgot Password',
        Component: (props) => <ForgotPassword {...props} />,
        url: '/forgot-password',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet'],
        meta: {
            title: 'Şifremi unuttum',
            description: '',
            keywords: '',
        },
        logoutRequired: true,
    },
    resetPassword: {
        name: 'Reset Password',
        Component: (props) => <ResetPassword {...props} />,
        url: '/passwordreset',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Şifre yenileme',
            description: '',
            keywords: '',
        },
        logoutRequired: true,
    },
    changePassword: {
        name: 'Change password',
        Component: (props) => <ChangePassword {...props} />,
        url: '/change-password',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet'],
        meta: {
            title: 'Şifre Değiştir',
            description: '',
            keywords: '',
        },
        loginRequired: true,
    },
    promoPage: {
        name: 'Promopage',
        Component: (props) => <PromoPage {...props} />,
        url: '/promo',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description:
                'EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.',
            keywords: 'bonus, bahis bonusları, casno bonusları, bonus veren siteler',
        },
    },
    aboutUsPage: {
        name: 'About Us',
        Component: (props) => <AboutUsPage {...props} />,
        url: '/aboutus',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Hakkımızda',
            description: '',
            keywords: '',
        },
    },
    myAccountPage: {
        name: 'My account',
        Component: (props) => <MyAccountPage {...props} />,
        url: '/account',
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Hesabım',
            description: '',
            keywords: '',
        },
        loginRequired: true,
        allowedForBlocked: true,
    },
    betOnGames: {
        name: 'Bet Games',
        Component: (props) => <BetOnGames {...props} />,
        url: '/bet-games',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description:
                "EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.",
            keywords: 'slot, slot oyna, slot oyunları, canlı slot',
        },
    },
    twain: {
        name: 'Twain',
        Component: (props) => <Twain {...props} />,
        url: '/twain',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Twain',
            description:
                "Twain",
            keywords: 'Twain',
        },
    },
    liveGames: {
        name: 'Live games',
        Component: (props) => <LiveGames {...props} />,
        url: '/live-games',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Tombala | EfsaneBahis',
            description:
                "Canlı tombala alanında en çok tombala oyun çeşiti ile en iyi tombala sitesi olan EfsaneBahis'ya gelin, tombala oyunlarında kazanın!",
            keywords: 'tombala, canlı tombala, tombala oyunları, tombala oyna',
        },
    },
    lottery: {
        name: 'Lottery',
        Component: (props) => <Lottery {...props} />,
        url: '/lottery',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'EfsaneBahis | Spor Bahisleri ve Casino | EfsaneBahis Resmi',
            description: 'EfsaneBahis Spor Bahisleri ile yüksek oranlı bahis yapmanın keyfini yaşayın. EfsaneBahis ile oynadığın her kupona nakit para kazan. Efsanebahis Kazandırır.',
            keywords: 'loto, loto oyna',
        },
    },
    tvbet: {
        name: 'TVBET',
        Component: (props) => <TvBet {...props} />,
        url: '/tvbet',
        exact: true,
        loginRequired: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'TVBET',
            description: '',
            keywords: '',
        },
    },
    helpPage: {
        name: 'Contact Us',
        Component: (props) => <ContactUsPage {...props} />,
        url: '/contact-us',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Bize Ulaşın',
            description: '',
            keywords: '',
        },
    },
    mailVerify: {
        name: 'Mail Verify',
        Component: (props) => <MailVerifyPage {...props} />,
        url: '/mailverify',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'E-Posta doğrulama',
            description: ' ',
            keywords: ' ',
        },
    },
    messages: {
        name: 'Messages',
        Component: (props) => <MessagesPage {...props} />,
        url: '/user/messages',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet'],
        meta: {
            title: 'Mesajlar',
            description: '',
            keywords: '',
        },
        loginRequired: true,
    },
    lobbyPage: {
        name: 'Lobby',
        Component: (props) => <Lobby {...props} />,
        url: '/lobby',
        exact: true,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: 'Lobby',
            description: '',
            keywords: '',
        },
        loginRequired: true,
    },
    error404Page: {
        name: 'Error',
        Component: (props) => <ErrorPage {...props} />,
        allowedViewSizes: ['mobile', 'tablet', 'desktop'],
        meta: {
            title: '404',
            description: '',
            keywords: '',
        },
    },
};

export default efsanebahisPagesConfig;
