import React, {Fragment, useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';

import {
    LLScrollBox,
    BetradarWidgets,
    LLPreloader,
} from '../../../../components';
import {LLSportWidget, RangedEventsPicker, VAIXWidget} from '../../components';
import LLMatchesListItem from './matches-list-item';
import LLOutrightListItem from './outright-list-item';
import {trans, getSportIconsClassnames} from '../../../../common/utils';
import config from '../../../../static/themes/config';


const LLMatchesList = ({store, view: {view, isLoading}}) => {
    const
        branchActiveTournament = store.betting.branch.activeTournament,
        branchActiveCategory = store.betting.branch.activeCategory,
        viewSize = store.site.status.viewSize,
        branch = store.betting.branch,
        siteName = store.site.siteName,
        rangedEventsMatches = store.betting.rangedEvents.rangedEventsMatches;

    const [activeOutrightsGroup, setActiveOutrightsGroup] = useState(0);

    const activeCategory = branchActiveCategory();

    useEffect(() => setActiveOutrightsGroup(0), [isLoading]);

    const renderLoading = () => {
        return (
            <div className="prematch__loading">
                <LLPreloader height={50} width={50}/>
            </div>
        );
    };

    const renderBreadcrumbs = (match) => (
        <div
            className="prematch__breadcrumbs prematch__breadcrumbs--list"
            data-sport-id={match.sportId}
            data-category-id={match.categoryId}
            data-tournament-id={match.tournamentId}
        >
            <i
                className={classNames(
                    'prematch__breadcrumbs-sport-icon',
                    getSportIconsClassnames(
                        config(siteName).siteConfig.appearanceRules.sportIcons,
                        store.site.status.viewSize,
                        match.sportId
                    )
                )}
            />
            <p className="prematch__breadcrumbs-content">
                <span className="prematch__breadcrumbs-text">
                    {match.nameOf('sport')}
                </span>
                <span className="prematch__breadcrumbs-separator">&gt;</span>
                <span className="prematch__breadcrumbs-text">
                    {match.nameOf('category')}
                </span>
                {match.nameOf('tournament') && (
                    <>
                        <span className="prematch__breadcrumbs-separator">&gt;</span>
                        <span className="prematch__breadcrumbs-text">
                            {match.nameOf('tournament')}
                        </span>
                    </>
                )}
            </p>
        </div>
    );

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                               Single tournament matches                              ##
    // ##                                                                                      ##
    // ##========================================================================================

    const renderSingleTournamentMatches = () => {
        const activeTournament = branchActiveTournament();
        const singleTournamentMatches = activeTournament?.matchesGroupedByDate();
        if (!singleTournamentMatches) return null;
        const singleMatchInstance = Object.values(singleTournamentMatches)[0][0];
        return (
            <>
                {singleMatchInstance && renderBreadcrumbs(singleMatchInstance)}
                {
                    Object.keys(singleTournamentMatches).map((data, i) => (
                        <Fragment key={i}>
                            <div className="match-list__date">{data}</div>
                            {singleTournamentMatches[data].map((match, y) => (
                                <LLMatchesListItem
                                    key={y}
                                    match={match}
                                    customClasses={
                                        (Object.keys(singleTournamentMatches).length - 1 === i &&
                                            singleTournamentMatches[data].length - 1 === y
                                        ) ? 'match--last-in-list' : ''
                                    }
                                />
                            ))}
                        </Fragment>
                    ))
                }
                {!config(siteName).siteConfig.withOutWidgets &&
                    <BetradarWidgets tournamentId={activeTournament.id} type="season.liveTable"/>}
            </>
        );
    };

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                                 All category matches                                 ##
    // ##                                                                                      ##
    // ##========================================================================================

    const renderAllTournamentsMatches = () => {
        return tournamentsBlock({
            tournaments: activeCategory?.allMatches ?? [],
        });
    };

    // ##========================================================================================
    // ##                                                                                      ##
    // ##                               Top and favorite matches                               ##
    // ##                                                                                      ##
    // ##========================================================================================

    const renderTopFavoriteMatches = () => {
        const tournamentsWithTopMatches = branch.tournamentsWithTopFavoriteMatches(
            'Top'
        );
        const tournamentsWithFavoriteMatches = branch.tournamentsWithTopFavoriteMatches(
            'Favorite'
        );
        return (
            <>
                {!!tournamentsWithFavoriteMatches?.length && (
                    <>
                        <div className="match-list__top-fav-title match-list__top-fav-title--favorite">
                            {trans('PRE_FAVORITE')}
                        </div>
                        {tournamentsWithFavoriteMatches.map((tournament) =>
                            tournamentsBlock({
                                tournaments: [
                                    tournament.matchesGroupedByDate('matchesFavorite'),
                                ],
                            })
                        )}
                    </>
                )}
                {!!tournamentsWithTopMatches?.length && (
                    <>
                        <div className="match-list__top-fav-title match-list__top-fav-title--top">
                            {trans('PRE_TOP')}
                        </div>
                        {tournamentsWithTopMatches.map((tournament) =>
                            tournamentsBlock({
                                tournaments: [
                                    tournament.matchesGroupedByDate('matchesTop', true),
                                ],
                            })
                        )}
                    </>
                )}
            </>
        );
    };

    const tournamentsBlock = ({tournaments}) => {
        if (!tournaments.length) return null;

        return tournaments.map((tournament, i) => {
            if (tournament && Object.values(tournament).length) {
                const firstMatchInstance = Object.values(tournament)[0][0];
                return (
                    <Fragment key={i}>
                        {firstMatchInstance && renderBreadcrumbs(firstMatchInstance)}
                        {Object.keys(tournament).map((data, i) => (
                            <Fragment key={i}>
                                <div className="match-list__date">{data}</div>
                                {tournament[data].map((match, y) => (
                                    <LLMatchesListItem
                                        key={y}
                                        match={match}
                                        customClasses={
                                            (Object.keys(tournament).length - 1 === i &&
                                                tournament[data].length - 1 === y
                                            ) ? 'match--last-in-list' : ''
                                        }
                                    />
                                ))}
                            </Fragment>
                        ))}
                    </Fragment>
                );
            } else {
                return null;
            }
        });
    };

    const renderOutrights = () => {
        const allOutrightsTournaments = activeCategory.allOutrightsTournaments;
        if (!allOutrightsTournaments.length) return null;
        const firstOutright = allOutrightsTournaments[0]?.outrigthsList[0];
        return (
            <>
                {firstOutright && renderBreadcrumbs(firstOutright)}
                {allOutrightsTournaments.map((tournament, i) => {
                    const outrights = tournament.outrigthsList;
                    return (
                        <div
                            key={i}
                            className="ll-outright-tournament"
                            onClick={() =>
                                setActiveOutrightsGroup(
                                    activeOutrightsGroup === i ? null : i
                                )
                            }
                        >
                            <div
                                className={`ll-outright-tournament__accordion${
                                    activeOutrightsGroup === i ? '--active' : ''
                                }`}
                            >
                                {tournament.name}
                                <i className="ll-outright-tournament__accordion-arrow doruk-mg-arrow-2"/>
                            </div>
                            <Collapse in={activeOutrightsGroup === i}>
                                {outrights.map((outright, i) => (
                                    <LLOutrightListItem outright={outright} key={i}/>
                                ))}
                            </Collapse>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderGroupedEvents = (data, type) => {
        const noDataText = {
            rangedEvents: 'RANGED_EVENTS_NO_DATA_TEXT',
            search: 'SPORTS_SEARCH_NO_DATA_TEXT'
        }

        if (!Object.keys(data).length)
            return (
                <p className="match-list__search-no-data-text">
                    {trans(noDataText[type])}
                </p>
            );

        return Object.keys(data).map((tournamentId) => {
            const tournament = data[tournamentId];

            return (
                <Fragment key={tournamentId}>
                    {tournament && renderBreadcrumbs(tournament)}
                    {Object.keys(tournament.matches).map((matchStartDate, i) => {
                        const matchesByDate = tournament.matches[matchStartDate];
                        return (
                            <Fragment key={matchStartDate}>
                                <div className="match-list__date">{matchStartDate}</div>
                                {matchesByDate.map((match, y) => {
                                    return <LLMatchesListItem
                                        key={y}
                                        match={match}
                                        customClasses={
                                            (Object.keys(tournament.matches).length - 1 === i &&
                                                matchesByDate.length - 1 === y
                                            ) ? 'match--last-in-list' : ''
                                        }
                                    />
                                })}
                            </Fragment>
                        );
                    })}
                </Fragment>
            );
        });
    }

    const renderRangedEvents = () => renderGroupedEvents(rangedEventsMatches, 'rangedEvents');

    return viewSize === 'tablet' ? (
            <LLScrollBox id="match-list" customClasses="match-list">
                <LLSportWidget/>
                <VAIXWidget />
                <RangedEventsPicker/>
                {isLoading
                    ? renderLoading()
                    : {
                        singleTournamentMatches: renderSingleTournamentMatches,
                        topFavoriteMatches: renderTopFavoriteMatches,
                        allTournamentsMatches: renderAllTournamentsMatches,
                        outrights: renderOutrights,
                        rangedEvents: renderRangedEvents,
                    }[view]()}
            </LLScrollBox>)
        :
        (<>
                <LLSportWidget/>
                <RangedEventsPicker/>
                <LLScrollBox id="match-list" customClasses="match-list">
                    {isLoading
                        ? renderLoading()
                        : {
                            singleTournamentMatches: renderSingleTournamentMatches,
                            topFavoriteMatches: renderTopFavoriteMatches,
                            allTournamentsMatches: renderAllTournamentsMatches,
                            outrights: renderOutrights,
                            rangedEvents: renderRangedEvents,
                        }[view]()}
                </LLScrollBox>
            </>
        );
};

export default inject('store')(observer(LLMatchesList));
